import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleClient,
  fetchClients,
  updateClient,
  breakConnection,
  fetchLatestClients,
  //   singleUserUpdate,
  //   changeUserSubscription,
} from "../../redux/modules/clients/actions";
import {
  updateUserData,
  //   singleUserUpdate,
  //   changeUserSubscription,
} from "../../redux/modules/user/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm } from "react-hook-form";

import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useModalContext } from "../../SuccessModalContext";
const SingleClient = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleClient, hasError } = useSelector((state) => state.clientsData);
  const [removeModal, setRemoveModal] = useState(false);
  const { user } = useSelector((state) => state.userData);
  const { setOpenState } = useModalContext();
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchSingleClient(id));
  }, []);

  const [values, setValues] = useState({
    id: "",
    name: "",
    phone: "",
    models3D: [],
  });
  useEffect(() => {
    if (singleClient) {
      setValues({
        id: singleClient?.id,
        name: singleClient?.name,
        phone: singleClient?.phone,
        models3D: singleClient?.models3D,
      });
    }
  }, [singleClient]);

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    reset(singleClient);
  }, [singleClient]);
  const callback = () => {
    dispatch(updateUserData());
    dispatch(fetchClients());
    dispatch(fetchLatestClients());
    setOpenState(true);
    navigate("/my-clients");
  };
  const removeSubmit = () => {
    dispatch(
      breakConnection(
        { endUser: singleClient.id, currentUser: user.id },
        callback
      )
    );
  };
  const submitUpdate = (data) => {
    dispatch(
      updateClient(
        singleClient.id,
        { phone: data.phone, name: data.name },
        callback
      )
    );
  };
  const backendErrors = () => {
    if (!hasError || hasError.length <= 0) {
      return;
    }

    return hasError.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Profile={
          singleClient?.profilePicture ? (
            <img
              src={`${process.env.REACT_APP_API}${singleClient.profilePicture.usePath}/${singleClient.profilePicture.filename}`}
              alt={singleClient.name}
              className="w-full object-cover h-full"
            />
          ) : (
            <FaceIcon className="text-white " />
          )
        }
        title={singleClient.name ? singleClient.name : singleClient.phone}
        buttonIcon={<DeleteForeverIcon />}
        buttonTitle={"Remove this client"}
        action={() => setRemoveModal(true)}
        buttonSecondary={true}
        backLink="/my-clients"
      />
      <div className="w-full h-full flex flex-col justify-around">
        <div className="py-2 align-middle inline-block min-w-full w-full">
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-start items-start py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="name"
                  // disabled={true}
                  // type={showPassword ? "text" : "password"}
                  defaultValue={singleClient.name}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>

            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  // disabled={true}
                  fieldName="phone"
                  defaultValue={singleClient.phone}
                  placeholder={"+CC XXX XXX XXX"}
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                  }}
                />
              </div>

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>

            <div className="w-full p-2">
              <MainButton action={handleSubmit(submitUpdate)} title="Save" />
            </div>
            <ul className="w-full flex justify-center items-center">
              {backendErrors()}
            </ul>
          </form>
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8">
            <div className="flex justify-center text-center p-2 font-bold text-xl">
              {" "}
              Models
            </div>

            {/* <ModelsList
            listData={singleClient ? [singleClient.models3D] : [[]]}
            modalShow={() => {}}
          /> */}
            {values && values.models3D && values.models3D.length ? (
              <ModelsList
                extraLink={"/model"}
                listData={[
                  values ? (values.models3D ? values.models3D : []) : [],
                ]}
                modalShow={() => {}}
                ex
              />
            ) : (
              <Empty
                link={user.role === "Admin" ? "/models" : "/my-models"}
                buttonTitle="Models"
                info="Go to your models and Assign to this user"
              />
            )}
          </div>
          <RemoveModal
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            buttonText={"Remove client"}
            title="Are you sure you want to remove this user"
            description={`This action is irreversible.`}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleClient;
