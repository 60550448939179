import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleEndUser,
  singleEndUserUpdate,
  removeEndUser,
  fetchEndUsers,
} from "../../redux/modules/admin/actions";
import {
  breakConnection,
  //   singleUserUpdate,
  //   changeUserSubscription,
} from "../../redux/modules/clients/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm, Controller } from "react-hook-form";
import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { useModalContext } from "../../SuccessModalContext";
const ViewEndOptions = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { error, singleEndUser } = useSelector((state) => state.adminData);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { setOpenState } = useModalContext();
  const [removeModal, setRemoveModal] = useState(false);

  const [currentDeletedUser, setCurrentDeletedUser] = useState(null);

  const closeRemoveUserModal = () => {
    setCurrentDeletedUser(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleEndUser(id));
  }, []);
  const navigate = useNavigate();
  const callback = () => {
    dispatch(fetchSingleEndUser(id));
    navigate("/end-users");
    setOpenState(true);
  };
  const submit = (data) => {
    dispatch(
      singleEndUserUpdate(
        singleEndUser.user?.id,
        { name: data.name, phone: data.phone },
        callback
      )
    );
  };

  const removeUserSubmit = () => {
    dispatch(
      breakConnection(
        { endUser: id, currentUser: currentDeletedUser.id },
        callbackRemoveAUser
      )
    );
  };

  const callbackRemoveAUser = () => {
    dispatch(fetchSingleEndUser(id));
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    reset(singleEndUser.user);
  }, [singleEndUser]);
  const callbackRemove = () => {
    dispatch(fetchEndUsers());
    navigate("/end-users");
  };
  const removeSubmit = () => {
    dispatch(removeEndUser(id, callbackRemove));
  };
  const backendErrors = () => {
    return error.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/end-users"}
        Profile={
          singleEndUser.user?.profilePicture &&
          singleEndUser.user?.profilePicture.usePath ? (
            <img
              src={`${process.env.REACT_APP_API}${singleEndUser.user?.profilePicture?.usePath}/${singleEndUser.user?.profilePicture?.filename}`}
              alt={singleEndUser.user.name}
              className="w-full object-cover h-full"
            />
          ) : (
            <FaceIcon className="text-white " />
          )
        }
        buttonIcon={<DeleteForeverIcon />}
        buttonTitle={"Remove this end user"}
        action={() => setRemoveModal(true)}
        buttonSecondary={true}
        title={
          singleEndUser.user?.name
            ? singleEndUser.user?.name
            : singleEndUser.user?.phone
        }
      />
      <div className="w-full h-full flex flex-col justify-around">
        <div className="py-2 align-middle inline-block min-w-full w-full">
          <form className="shadowBox overflow-hidden  bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full flex items-center justify-start flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-start items-start py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="name"
                    type="text"
                    // disabled={true}
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleEndUser.user?.name}
                    placeholder={"Name"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="phone"
                  // disabled={true}
                  defaultValue={singleEndUser.user?.phone}
                  placeholder={"+CC XXX XXX XXX"}
                  type="text"
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                  }}
                />
              </div>

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Related architects
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    // No = "no",
                    // TierOne = "tierOne",
                    // TierTwo = "tierTwo",
                    return (
                      <input
                        ref={ref}
                        disabled={true}
                        value={singleEndUser.user?.connectedAUsers.length}
                        type="text"
                        placeholder="New name"
                        className="w-full py-3"
                        // className="uploadModelForm-textField bigTextField"
                      />
                    );
                  }}
                  defaultValue={singleEndUser.user?.connectedAUsers.length}
                  name="connectedAUsers"
                />
              </div>
            </div>

            <div className="w-full p-2">
              <MainButton action={handleSubmit(submit)} title="Save" />
            </div>
            <ul className="w-full flex flex-col justify-center items-center">
              {backendErrors()}
            </ul>
          </form>

          <div className="mt-4 shadowBox overflow-hidden overflow-x-auto bg-white  px-2 sm:px-6 lg:px-8 ">
            <div className="flex justify-center text-center p-2 font-bold text-xl">
              {" "}
              Related architect details
            </div>
            <table className="min-w-full divide-y divide-gray-200 ">
              <tbody className="bg-white divide-y divide-gray-200">
                {singleEndUser.user?.connectedAUsers &&
                singleEndUser.user?.connectedAUsers.length > 0 ? (
                  <>
                    {singleEndUser.user.connectedAUsers.map((item, index) => {
                      // const checked = true;

                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm flex flex-row font-medium text-gray-900 ml-4">
                              <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                                {item.profilePicture &&
                                item.profilePicture.usePath ? (
                                  <img
                                    className="object-cover w-full h-full"
                                    src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                                  />
                                ) : (
                                  <FaceIcon className="text-white" />
                                )}
                              </div>

                              {/* <div className="text-sm flex flex-col justify-center mx-4 text-gray-500">
                                <div>{item.phone}</div> <div> {item.email}</div>
                              </div>
                              <div className="flex justify-center items-center">
                                {item.name}
                              </div> */}
                              <div className="text-sm font-medium text-gray-900 ml-4">
                                {item.name}
                                <div className="text-sm text-gray-500">
                                  {item.phone} {item.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className=" text-sm text-gray-900 flex justify-center items-center">
                              conntected to {item.connectedEndUsers.length} End
                              user
                              {""}
                              {item.connectedEndUsers.length !== 1 && (
                                <>{"s"}</>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-xs mr-2 flex font-bold outline-none  text-red-500 justify-center items-center"
                              onClick={() => {
                                setCurrentDeletedUser(item);
                              }}
                            >
                              <DeleteForeverIcon />
                              Break connection
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <Empty info="This user has no clients connected" />
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadowBox overflow-hidden  bg-white  px-2 sm:px-6 lg:px-8 py-4">
            <div className="text-textTitle flex items-center justify-center text-xl font-bold text">
              Models
            </div>
            {singleEndUser &&
            singleEndUser.user?.models3D &&
            singleEndUser.user?.models3D.length ? (
              <ModelsList
                listData={[
                  singleEndUser
                    ? singleEndUser.user?.models3D
                      ? singleEndUser.user.models3D
                      : []
                    : [],
                ]}
                modalShow={() => {}}
              />
            ) : (
              <Empty
                link="/models"
                buttonTitle="Models"
                info="Go to your models and Assign to this user"
              />
            )}
          </div>
          <RemoveModal
            visibility={currentDeletedUser !== null}
            setVisibility={closeRemoveUserModal}
            submitAction={removeUserSubmit}
            title="Are you sure you want to remove this user?"
            description={`You can connect it back later`}
            buttonText="Break connection"
          />
          <RemoveModal
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            buttonText={"Remove user"}
            title="Are you sure you want to remove this user?"
            description={`This action is irreversible.`}
          />
          <SuccessModal
            visibility={showSuccessModal}
            setVisibility={setShowSuccessModal}
            title={"The update operation succeeded"}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewEndOptions;
