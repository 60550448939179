import {
  ADMIN_FETCH_USERS,
  ADMIN_ERROR,
  ADMIN_SUCCESS,
  ADMIN_LOADING,
  ADMIN_FETCH_MODELS,
  ADMIN_FETCH_SINGLE_MODEL,
  ADMIN_CREATE_ARCHITECT,
  ADMIN_FETCH_END_USERS,
  ADMIN_ERROR_CLEAR,
  ADMIN_FETCH_SINGLE_USER,
  ADMIN_FETCH_SINGLE_END_USER,
  ADMIN_FETCH_USERS_SEARCH,
  ADMIN_FETCH_LATEST_USERS,
  DUPLICATE_MODEL,
  FETCH_PARTNERS,
  // FETCH_CLIENTS,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";
export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};
export function error(data) {
  return {
    type: ADMIN_ERROR,
    payload: data,
  };
}

export function loading() {
  return {
    type: ADMIN_LOADING,
  };
}

export function success(data) {
  return {
    type: ADMIN_SUCCESS,
    payload: data,
  };
}
export function fetchUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_USERS,
    payload: data,
  };
}
export function fetchLatestUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_LATEST_USERS,
    payload: data,
  };
}
export function fetchUsersSearchSuccess(data) {
  return {
    type: ADMIN_FETCH_USERS_SEARCH,
    payload: data,
  };
}
export function fetchModelsSuccess(data) {
  return {
    type: ADMIN_FETCH_MODELS,
    payload: data,
  };
}
export function fetchSingleModelsSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_MODEL,
    payload: data,
  };
}
export function createdArchitectUser(data) {
  return {
    type: ADMIN_CREATE_ARCHITECT,
    payload: data,
  };
}

export function fetchEndUsersSuccess(data) {
  return {
    type: ADMIN_FETCH_END_USERS,
    payload: data,
  };
}
export function fetchSingleUserSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_USER,
    payload: data,
  };
}
export function fetchSingleEndUserSuccess(data) {
  return {
    type: ADMIN_FETCH_SINGLE_END_USER,
    payload: data,
  };
}
export function duplicateModelSuccess(data) {
  return {
    type: DUPLICATE_MODEL,
    payload: data,
  };
}
export function fetchPartnersSuccess(data) {
  return {
    type: FETCH_PARTNERS,
    payload: data,
  };
}
// export function clientCreated(data) {
//   return {
//     type: CLIENT_CREATE_SUCCESS,
//     payload: data,
//   };
// }
export function errorClear() {
  return {
    type: ADMIN_ERROR_CLEAR,
  };
}
export function fetchUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    // change url when you have it
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/a-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function fetchLatestUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    // change url when you have it
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/a-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchLatestUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}

export function fetchEndUsers(page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    // change url when you have it
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/end-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return dispatch(fetchEndUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function searchUsers(search, page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/a-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return dispatch(fetchUsersSearchSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function searchEndUsers(search, page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/end-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return dispatch(fetchEndUsersSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function fetchModels(page, limit) {
  return (dispatch) => {
    dispatch(loading());

    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/admin/models${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function searchModels(search, filters, page, limit) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/admin/models/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export const downloadSingleModel = (id, name) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/model/download/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${id}-${name}.zip`); // or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(success("Download started"));
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const fetchSingleModel = (id) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/model/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleModelsSuccess(response.data));
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const uploadFinalModel =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/model/ar-model/${id}`,
        formData,

        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success("Uploaded AR ready model"));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
export const changeUserSubscription =
  (id, data, callback) => async (dispatch) => {
    dispatch(loading());

    const token = getCookie("uac");
    // change url when you have it
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/users/a-user/subscription/${id}`,
        { subscribed: data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success("Subscription changed"));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
export const addArchitectUser = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  // change url when you have it
  axios
    .post(`${process.env.REACT_APP_API}/admin/users/a-user/create`, data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(createdArchitectUser(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const fetchSingleUser = (id) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/users/a-user/single/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleUserSuccess(response.data));
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const fetchSingleEndUser = (id) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .get(`${process.env.REACT_APP_API}/admin/users/end-user/single/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(fetchSingleEndUserSuccess(response.data));
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const singleUserUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .put(`${process.env.REACT_APP_API}/a-users/details/${id}`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(success("User updated"));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(error(err.response.data.errors));
      });
  };
export const singleEndUserUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/users/end-user/update/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(success("User updated"));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        }
        dispatch(error(err.response.data.errors));
      });
  };
export const removeEndUser = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/admin/users/end-user/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export const removeAUser = (id, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  axios
    .delete(`${process.env.REACT_APP_API}/admin/users/a-user/remove/${id}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.message) return;
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        dispatch(error(err.response.data.errors));
      }
    });
};
export function duplicateModelAction(id, data, callback) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");

    axios
      .post(`${process.env.REACT_APP_API}/admin/models/duplicate/${id}`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(duplicateModelSuccess(response.data));

        if (callback) callback(response.data.id);
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export function fetchPartners({ search = "", page = 0, limit = 12 }) {
  return (dispatch) => {
    dispatch(loading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .get(
        `${process.env.REACT_APP_API}/admin/users/partner-user?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        return dispatch(fetchPartnersSuccess(response.data));
      })
      .catch((err) => {
        if (err.message) return;
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          dispatch(error(err.response.data.errors));
        }
      });
  };
}
export const addPartner = (data, callback) => async (dispatch) => {
  dispatch(loading());
  const token = getCookie("uac");
  // change url when you have it
  axios
    .post(
      `${process.env.REACT_APP_API}/admin/users/partner-user/create`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      dispatch(success(response.data));
      if (callback) callback();
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(clearAllStoreData());
      } else {
        if (err.response && err.response.data)
          dispatch(error(err.response.data.errors));
      }
    });
};
export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
