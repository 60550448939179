import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import {
  Autocomplete,
  TextField,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import {
  uploadModel,
  getParrentCategories,
  getChildCategories,
  // getChildCategories,
} from "../../../redux/modules/models/actions";
// import { getHasError, getIsLoading, getModels } from "../../reducers/model";
import logo from "../../../staticfiles/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { ControlledInput as Input } from "../../ui/forms/ControlledInput";
import { useForm, Controller } from "react-hook-form";
import MainButton from "../buttons/MainButton";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../SuccessModalContext";

import { Box } from "@mui/system";
const UploadModelForm = (props) => {
  const [filesModel, setFilesModel] = React.useState([]);
  const [filesCoverImageZon, setFilesCoverImageZone] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [parrentCategoryValues, setParrentCategoryValues] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userData);
  const { error, parrentCategories, categories } = useSelector(
    (state) => state.modelData
  );
  const [finalResponse, setFinalResponse] = React.useState({
    model: [], // file
    coverImage: [], // img
    material: {}, // file
    textures: logo, // img
    name: "",
    otherDetail: "",
    description: "",
    rooms: "",
    bathrooms: "",
    area: "",
    materials: "",
    marketplace: false,
    numberOfFloors: 1,
    floorHeight: 2,
  });
  const [fileErrors, setFileErrors] = useState({
    model: "",
    coverImage: "",
  });
  const [formData, setFormData] = useState(new FormData());

  const { setOpenState } = useModalContext();
  React.useEffect(() => {
    if (filesCoverImageZon.length) {
      setFinalResponse((prevState) => ({
        ...prevState,
        coverImage: filesCoverImageZon,
      }));
      filesCoverImageZon.forEach((image) => {
        formData.append("coverImage", image);
      });
    }
  }, [filesCoverImageZon]);

  React.useEffect(() => {
    if (filesModel.length) {
      setFinalResponse((prevState) => ({
        ...prevState,
        model: filesModel,
      }));
      formData.set("model", filesModel[0]);
    }
  }, [filesModel]);
  const convertToFormData = (values) => {
    Object.keys(values).map((val) => {
      if (val === "materials") {
        formData.set(val, values[val].join());
      }
      if (val !== "coverImage" || val !== "model")
        formData.set(val, values[val]);
    });
  };

  const callback = () => {
    if (user.role === "Admin") {
      navigate("/models");
    } else if (user.role === "userA") {
      navigate("/my-models");
    }
    setButtonDisabled(false);
    setOpenState(true);
  };
  const onError = () => {
    setButtonDisabled(false);
  };
  const handleUpload = (data) => {
    convertToFormData(data);
    if (!finalResponse.model || finalResponse.model.length <= 0) {
      setFileErrors({
        ...fileErrors,
        model: "A model is required",
        coverImage: "",
      });
      setButtonDisabled(false);
      return;
    }
    if (!finalResponse.coverImage || finalResponse.coverImage.length <= 0) {
      setFileErrors({
        ...fileErrors,
        model: "",
        coverImage: "A cover image is required",
      });
      setButtonDisabled(false);
      return;
    }
    setButtonDisabled(true);

    for (const value of formData.values()) {
      console.log(value);
    }
    dispatch(uploadModel(formData, callback, onError));
  };

  useEffect(() => {
    dispatch(getParrentCategories());
    setFormData(new FormData());
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ".obj,.fbx,.OBJ,.FBX,.glb,.GLB,.gltf,.GLTF",
    // multiple: false,
    onDrop: (acceptedFiles) => {
      setFilesModel(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const coverImageZone = useDropzone({
    accept: "image/*",
    multiple: true,
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      setFilesCoverImageZone(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });
  const fileRejectionItemsModel = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul className="w-full text-center">
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be 3D model"
              : e.message}
            <br />
            {file.path}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileRejectionItemsCover = () => {
    if (
      coverImageZone.fileRejections &&
      coverImageZone.fileRejections.length > 0
    ) {
      return (
        <div>
          <ul className="w-full text-center">
            {coverImageZone.fileRejections[0].errors.map((e) => (
              <li className="text-red-500 font-bold" key={e.code}>
                {e.code === "file-invalid-type"
                  ? "The file must be image"
                  : e.message}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };
  // } .map(
  //   ({ file, errors }) => (

  //
  //       </ul>
  //     </div>
  //   )
  // );
  const fileAccpetedItemsCover = finalResponse.coverImage.map((file, i) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });
  const fileAccpetedItemsModel = finalResponse.model.map((file, i) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });
  const {
    handleSubmit,

    control,
    formState: { errors },
  } = useForm();

  const backendErrors = () => {
    if (error && error.length > 0)
      return error.map((e) => (
        <li className="text-xs text-red-500 " key={e.code}>
          {e.message}
          <br />
        </li>
      ));
  };
  const handleChangeParrentCategory = (val) => (e) => {
    if (val) {
      setParrentCategoryValues(val.id);
    } else {
      setParrentCategoryValues(e.target.value);
    }
  };
  useEffect(() => {
    if (parrentCategoryValues)
      dispatch(getChildCategories(parrentCategoryValues));
  }, [parrentCategoryValues]);

  return (
    <div className="py-2 align-middle inline-block min-w-full w-full">
      <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
        <div className="w-full">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Model 3D<span className="text-xs text-red-500">*</span>
          </h3>
          <span className="text-xs text-blue-400">Mandatory field</span>
          <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
            <div
              className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2 p-12"
              {...getRootProps()}
            >
              <div className="uploadModelForm-image">
                <ImageIcon fontSize="large" color="disabled" />
              </div>
              <p className="text-center px-2 py-1">
                Drag & Drop files here or Browse Files
              </p>

              <input {...getInputProps()} />
              {fileRejectionItemsModel}
              {fileAccpetedItemsModel}
              <div className="uploadModelForm-subTitle">
                {" "}
                Max size: 50MB | File type: OBJ, FBX, GLB, GLTF{" "}
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {fileErrors.model && fileErrors.model}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Model name
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="name"
                type="text"
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.email}
                placeholder={"Model name"}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.name && errors.name.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Other detail
            </h3>
            <span className="text-xs text-blue-400 invisible">
              Not mandatory field
            </span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="otherDetail"
                type="text"
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.name}
                placeholder={"Other detail"}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.otherDetail && errors.otherDetail.message}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Parrent Category
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <div
                    className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                  >
                    <Autocomplete
                      disablePortal
                      className="w-full"
                      id="combo-box-demo"
                      onChange={(e, val) => {
                        if (val) {
                          onChange(val.id);
                        } else {
                          onChange(undefined);
                        }
                        handleChangeParrentCategory(val)(e);
                      }}
                      options={
                        parrentCategories.categories
                          ? parrentCategories.categories
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <div className="flex items-center justify-start flex-row">
                            <p> {option.name} </p>
                          </div>
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select a parrent category"
                          disableUnderline={true}
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          // onChange={(e) => {
                          //   onChange(e.target.value);
                          //   handleChangeParrentCategory(e);
                          // }}
                        />
                      )}
                    />
                  </div>
                );
              }}
              name="parrentCategory"
              rules={{
                required: {
                  value: true,
                  message: "Category is required",
                },
              }}
            />

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.parrentCategory && errors.parrentCategory.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Category
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <div
                    className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                  >
                    <Autocomplete
                      disablePortal
                      className="w-full"
                      id="combo-box-demo"
                      onChange={(e, val) => {
                        if (val) {
                          onChange(val.id);
                        } else {
                          onChange(undefined);
                        }
                        // handleChangeParrentCategory(val)(e);
                      }}
                      options={
                        parrentCategoryValues && categories.categories
                          ? categories.categories
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      noOptionsText={
                        parrentCategoryValues
                          ? "No options"
                          : "Select a parrent category first"
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <div className="flex items-center justify-start flex-row">
                            <p> {option.name} </p>
                          </div>
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select a category"
                          disableUnderline={true}
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          // onChange={(e) => {
                          //   onChange(e.target.value);
                          //   handleChangeParrentCategory(e);
                          // }}
                        />
                      )}
                    />
                  </div>
                );
              }}
              name="category"
              rules={{
                required: {
                  value: true,
                  message: "Category is required",
                },
              }}
            />

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.category && errors.category.message}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Number of floors
            </h3>

            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="numberOfFloors"
                type="number"
                // type={showPassword ? "text" : "password"}
                defaultValue={1}
                min={1}
                placeholder={"Number of Floors"}
                rules={{
                  pattern: {
                    value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                    message: "Please use numbers only",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.numberOfFloors && errors.numberOfFloors.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Floor height (m)
            </h3>

            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="floorHeight"
                type="number"
                min={1}
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.email}
                placeholder={"Floor height"}
                rules={{
                  pattern: {
                    value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                    message: "Please use numbers only",
                  },
                }}
              />
            </div>

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.floorHeight && errors.floorHeight.message}
            </p>
          </div>
        </div>
        <div className="w-full px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Client description
            <span className="text-xs text-red-500">*</span>
          </h3>
          <span className="text-xs text-blue-400">Mandatory field</span>
          <Controller
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <TextareaAutosize
                  className="textArea"
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="A few words about your client..."
                  onChange={(e) => onChange(e)}
                />
              );
            }}
            name="description"
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
            }}
          />
          <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
            {errors.description && errors.description.message}
          </p>
        </div>
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full sm:w-1/4 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Rooms<span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="rooms"
                type="number"
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.email}
                placeholder={"ex. 4"}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.rooms && errors.rooms.message}
            </p>
          </div>
          <div className="w-full sm:w-1/4 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Bathrooms
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="bathrooms"
                type="number"
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.name}
                placeholder={"ex. 3"}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.bathrooms && errors.bathrooms.message}
            </p>
          </div>
          <div className="w-full sm:w-1/4 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Materials
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>

            <Controller
              control={control}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <div
                    className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                  >
                    <Autocomplete
                      disablePortal
                      multiple
                      className="w-full"
                      id="material-box"
                      onChange={(e, val) => {
                        let arr = [];
                        val.map((value) => {
                          if (value) {
                            arr.push(value);
                          } else {
                            arr = [];
                          }
                        });

                        field.onChange(arr);
                        // handleChangeParrentCategory(val)(e);
                      }}
                      options={[
                        "Wood",
                        "Concrete",
                        "Stone",
                        "Metal",
                        "Traditional",
                      ]}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <div className="flex items-center justify-start flex-row">
                            <div className="bg-green-800 w-10 h-10 rounded-full m-1"></div>
                            <p> {option} </p>
                          </div>
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select materials"
                          disableUnderline={true}
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          // onChange={(e) => {
                          //   onChange(e.target.value);
                          //   handleChangeParrentCategory(e);
                          // }}
                        />
                      )}
                    />
                  </div>
                );
              }}
              name="materials"
              rules={{
                required: {
                  value: true,
                  message: "Material is required",
                },
              }}
            />

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.materials && errors.materials.message}
            </p>
          </div>
          <div className="w-full sm:w-1/4 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Area<span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="area"
                type="number"
                // type={showPassword ? "text" : "password"}
                // defaultValue={user.name}
                placeholder="ex. 120 sqm"
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.area && errors.area.message}
            </p>
          </div>
        </div>
        <div className="w-full">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Model Cover
            <span className="text-xs text-red-500">*</span>
          </h3>
          <span className="text-xs text-blue-400">Mandatory field</span>
          <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
            <div
              className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
              {...coverImageZone.getRootProps()}
            >
              <p className="text-center px-2 py-1">
                Drag & Drop files here or Browse Files
              </p>
              <p className="text-center px-2 py-1">File type: JPG, PNG </p>
              <input {...coverImageZone.getInputProps()} />
              {fileRejectionItemsCover()}
              {fileAccpetedItemsCover}
              <div className="flex flex-col sm:flex-row justify-center  items-center">
                {finalResponse.coverImage &&
                finalResponse.coverImage.length > 0 ? (
                  finalResponse.coverImage.map((image, i) => {
                    return (
                      <div className="w-full h-72 p-2 max-w-2xl" key={i}>
                        <img
                          src={image.preview}
                          alt="..."
                          className="w-full h-full rounded-xl object-cover"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="w-full h-full  flex flex-wrap justify-center py-1">
                    <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                      <ImageIcon fontSize="large" className="text-white" />
                    </div>
                  </div>
                )}
              </div>
              <div className="uploadModelForm-subTitle">
                {" "}
                Max size: 5MB | File type: JPG, JPEG, PNG{" "}
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {fileErrors.coverImage && fileErrors.coverImage}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Add this model to marketplace
          </h3>

          <div className={` flex justify-center  items-center`}>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return <Switch onChange={(e) => onChange(e.target.checked)} />;
              }}
              name="marketplace"
            />
          </div>
          <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
            {errors.marketplace && errors.marketplace.message}
          </p>
        </div>
        <div
          className={"w-full flex justify-center items-center flex-col py-4"}
        >
          <MainButton
            action={handleSubmit(handleUpload)}
            title="Save"
            width="w-full sm:w-2/6"
            disabled={buttonDisabled}
          />
          <p
            className="text-xs text-red-500 w-full text-center py-4 "
            style={{ minHeight: "1rem" }}
          >
            {fileErrors.model && fileErrors.model}
          </p>
          <ul>{backendErrors()}</ul>
        </div>
      </form>
    </div>
  );
};

export default UploadModelForm;
