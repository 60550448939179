import React from "react";
import { Link } from "react-router-dom";
const SquareButton = ({
  action,
  type,
  title,
  link,
  extraClasses,
  width,
  alert,
  Icon,
  padding,
  disabled,
  fill,
}) => {
  const classList = `relative text-center  ${
    fill
      ? "bg-gradient-to-r from-primary to-lightPrimary text-white"
      : " border-primary text-primary"
  } hover:shadow-lg outline-none focus:outline-nonenpm ease-linear transition-all duration-150 flex justify-center items-center
   cursor-pointer
   rounded-md
   mx-2
   ${extraClasses || ""} ${width || "w-full"} ${
    !padding ? "px-2 sm:px-4 py-2" : padding
  }`;
  if (link) {
    return (
      <Link to={link}>
        <div className={`${classList} border-1 square-button`}>
          {Icon && <span className={"mr-1"}>{Icon}</span>}
          {title}
        </div>
      </Link>
    );
  }
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={action || (() => {})}
      className={`${classList} border-1 square-button`}
    >
      {Icon && <span className={"mr-1"}>{Icon}</span>}
      {title}
    </button>
  );
};

export default SquareButton;
