import {
  UPLOAD_MODEL_SUCCESS,
  UPLOAD_MODEL_LOADING,
  UPLOAD_MODEL_HAS_ERROR,
  FETCH_MODELS_HAS_ERROR,
  FETCH_MODELS_LOADING,
  FETCH_MODELS_SUCCESS,
  ASSIGN_MODEL,
  REMOVE_ASSIGNMENT,
  MODELS_ERROR_CLEAR,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_COVER_SUCCESS,
  FETCH_SINGLE_MODEL_SUCCESS,
  DELETE_MODEL_SUCCESS,
  GET_CATEGORIES_SECOND,
  GET_CATEGORIES_PARRENT,
  FETCH_END_USER_FAVORITES,
  FETCH_END_USER_MODELS,
} from "./constants";

export const initialState = {
  models: {},
  singleModel: {},
  categories: {},
  parrentCategories: {},
  favorites: {},

  loading: false,
  error: [],
  success: false,
};

const modelLoadingError = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = action.payload;
  return newState;
};

const modelLoading = (state, action) => {
  const newState = { ...state };

  newState.loading = true;
  newState.error = [];
  return newState;
};

const modelLoadingSuccess = (state, action) => {
  const newState = { ...state };
  newState.singleModel = {};
  newState.models = action.payload;
  newState.loading = false;
  newState.error = [];

  return newState;
};
const getEndUserModelsSuccess = (state, action) => {
  const newState = { ...state };
  newState.singleModel = {};
  newState.models = action.payload;
  newState.loading = false;
  newState.error = [];

  return newState;
};
const fetchSingleModelSuccess = (state, action) => {
  const newState = { ...state };
  newState.singleModel = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
};
const getEndUserFavoritesSuccess = (state, action) => {
  const newState = { ...state };
  newState.favorites = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
};
const getCategoriesSecondSuccess = (state, action) => {
  const newState = { ...state };
  newState.categories = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
};
const getParrentCategoriesSuccess = (state, action) => {
  const newState = { ...state };
  newState.parrentCategories = action.payload;
  newState.loading = false;
  newState.error = [];
  return newState;
};
const modelUpdateSuccess = (state) => {
  const newState = { ...state };
  newState.loading = false;
  newState.error = [];
  return newState;
};

const uploadSuccess = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  newState.success = true;
  return newState;
};
const assignModelSuccess = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  newState.success = true;
  return newState;
};
const removeAssignmentSuccess = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  newState.success = true;
  return newState;
};
const modelUpdateCoverSuccess = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  newState.success = true;
  return newState;
};
const deleteModelSuccess = (state, action) => {
  const newState = { ...state };

  newState.loading = false;
  newState.error = [];
  newState.success = true;
  return newState;
};

function errorClear(state) {
  const newState = { ...state };

  newState.error = [];
  return newState;
}

const handlers = {
  [UPLOAD_MODEL_HAS_ERROR]: modelLoadingError,
  [UPLOAD_MODEL_SUCCESS]: uploadSuccess,
  [UPLOAD_MODEL_LOADING]: modelLoading,
  [FETCH_MODELS_HAS_ERROR]: modelLoadingError,
  [FETCH_MODELS_LOADING]: modelLoading,
  [FETCH_MODELS_SUCCESS]: modelLoadingSuccess,
  [ASSIGN_MODEL]: assignModelSuccess,
  [REMOVE_ASSIGNMENT]: removeAssignmentSuccess,
  [MODELS_ERROR_CLEAR]: errorClear,
  [UPDATE_MODEL_SUCCESS]: modelUpdateSuccess,
  [UPDATE_MODEL_COVER_SUCCESS]: modelUpdateCoverSuccess,
  [FETCH_SINGLE_MODEL_SUCCESS]: fetchSingleModelSuccess,
  [DELETE_MODEL_SUCCESS]: deleteModelSuccess,
  [GET_CATEGORIES_SECOND]: getCategoriesSecondSuccess,
  [GET_CATEGORIES_PARRENT]: getParrentCategoriesSuccess,
  [FETCH_END_USER_FAVORITES]: getEndUserFavoritesSuccess,
  [FETCH_END_USER_MODELS]: getEndUserModelsSuccess,
};

export default function modelData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
