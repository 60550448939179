import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetSignUpData } from "../../redux/modules/user/actions";
import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import logo from "../../staticfiles/Logo.png";
import GettingStartedForm from "../../Components/ui/forms/GettingStartedForm";
const SignUpEndUserPage = () => {
  const navigate = useNavigate();

  const { endUserSaved } = useSelector((state) => state.userData);
  const { isAuthenticated } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (endUserSaved) {
      dispatch(resetSignUpData());
      navigate("/signin");
    }
  }, [endUserSaved]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/marketplace");
    }
  }, [isAuthenticated]);
  return (
    <Page>
      <Container className="flex items-center">
        <a href="https://xreteh.com">
          <img
            src={logo}
            style={{ margin: "0 auto" }}
            className="logo h-20 w-48 sm:h-56 sm:w-72"
          />
        </a>
        <div className="z-10 w-full bg-white sm:mt-5 p-2 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <GettingStartedForm />
        </div>
        <div className="w-full flex justify-evenly my-2">
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/signin"
          >
            Already have an account?
          </Link>
          <Link
            className="text-primary font-medium text-center sm:text-right"
            to="/login"
          >
            Login as architect/partner
          </Link>
        </div>
      </Container>
    </Page>
  );
};

export default SignUpEndUserPage;
