import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardsNavbar from "./DashboardsNavbar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import Icon from "@material-tailwind/react/Icon";
// import H6 from "@material-tailwind/react/Heading6";
import { useDispatch } from "react-redux";
import { signout } from "../../../redux/modules/auth/actions";
import clearAllStoreData from "../../../redux/resetAction";
import logo from "../../../staticfiles/Logo.png";
import MainButton from "../buttons/MainButton";
const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState("-left-64");
  return (
    <>
      <DashboardsNavbar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        useMarketLog={false}
      />
      <div
        style={{ zIndex: "100" }}
        className={`min-h-screen fixed top-0 bottom-0 lg:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-20  px-6 transition-all duration-300`}
      >
        <div className="flex-col flex items-stretch min-h-screen flex-nowrap px-0 ">
          <NavLink to="/my-models" className="text-center w-full inline-block">
            <div className="logo-container">
              <img src={logo} />
            </div>
          </NavLink>
          <p className="text-fade my-4">ARCHITECT</p>
          <div className="flex flex-col flex-1 h-full">
            <ul className="flex-col min-w-full flex list-none flex-1 h-full relative py-4">
              {/* <li className="rounded-lg mb-2">
                <NavLink
                  to="/dashboard"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <DashboardIcon />
                  <span className="ml-2">Dashboard</span>
                </NavLink>
              </li> */}
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/my-models"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <HomeOutlinedIcon />
                  <span className="ml-2">My Models</span>
                </NavLink>
              </li>
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/my-clients"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <GroupIcon />
                  <span className="ml-2">My Clients</span>
                </NavLink>
              </li>
              {/* <li className="rounded-lg mb-2 ">
                <NavLink
                  disabled
                  to="/statistics"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <InsightsIcon />
                  Statistics
                </NavLink>
              </li> */}
              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/my-account"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <ManageAccountsIcon />
                  <span className="ml-2">My account</span>
                </NavLink>
              </li>
              <li className="rounded-lg mb-2 text-gray-700">
                <NavLink
                  to="/marketplace"
                  className={(navData) =>
                    navData.isActive
                      ? "flex items-center text-sm font-light px-4 py-2 rounded-lg text-primary "
                      : "flex items-center text-sm text-gray-700 font-light px-4 py-2 rounded-lg"
                  }
                >
                  <StorefrontIcon />
                  <span className="ml-2">Marketplace</span>
                </NavLink>
              </li>
              <li className="rounded-lg mb-2 ">
                <MainButton
                  Icon={<AddIcon className="text-xs" />}
                  link="/upload-model"
                  title="Upload new model"
                  padding="px-4 py-3"
                />
              </li>
              <li className="rounded-lg mb-2 text-gray-700   mt-auto">
                <button
                  className="flex items-center text-sm text-red-500 font-light px-4 py-2 rounded-lg"
                  onClick={() => {
                    dispatch(signout());
                    dispatch(clearAllStoreData());
                    navigate("/login");
                  }}
                >
                  <LogoutIcon />
                  <span className="ml-2">Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
