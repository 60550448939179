import React from "react";
import MainButton from "../ui/buttons/MainButton";

import mainImage from "../../assets/images/landing-page/graphics-home.png";
import backgroundImage from "../../assets/images/landing-page/City 1.png";
import Scroll from "react-scroll";
import { NavLink } from "react-router-dom";
import smallGraphics1 from "../../assets/images/landing-page/small-graphics-1.png";
import smallGraphics2 from "../../assets/images/landing-page/small-graphics-2.png";
import smallGraphics3 from "../../assets/images/landing-page/small-graphics-3.png";
const ScrollingLink = ({ children, isLocal, to, classes }) => {
  if (isLocal) {
    return (
      <Scroll.Link
        to={to.split("#")[1]}
        smooth={true}
        duration={500}
        className={classes + " cursor-pointer"}
      >
        {children}
      </Scroll.Link>
    );
  } else {
    return (
      <NavLink to={to} className={classes}>
        {children}
      </NavLink>
    );
  }
};
const HomeSection = () => {
  return (
    <div id="home">
      <div className="flex w-full lg:h-screen flex-col lg:flex-row my-24 lg:my-0">
        <div className="w-full bg-gray flex flex-col justify-center mx-4 px-12 my-24 lg:my-0 lg:mx-24 space-y-5 z-20 lg:w-1/2">
          <h1 className="text-7xl font-bold">
            Make Your Designs Come to Life!
          </h1>
          <p>
            The perfect platform for architects, AR designers and developers to
            create, share and monetize their work.
          </p>
          <div className="flex space-x-10 items-center justify-left">
            <MainButton title="Get started" link={"/signin"} />
            <ScrollingLink
              className="font-sans text-primary font-bold"
              to="/landing-page#howitworks"
              isLocal={true}
            >
              How it works?
            </ScrollingLink>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center z-10">
          <img src={mainImage}></img>
        </div>
        <div className="absolute bottom-0 z-0">
          <img src={backgroundImage}></img>
        </div>
        <div className="absolute hidden lg:flex top-50 left-1/2 z-0">
          <img src={smallGraphics1}></img>
        </div>
        <div className="absolute hidden lg:flex bottom-48 left-48 z-0">
          <img src={smallGraphics2}></img>
        </div>
        <div className="absolute hidden lg:flex top-1/3 left-1/4 z-0">
          <img src={smallGraphics3}></img>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
