import React from "react";

import DashboardsNavbar from "../../ui/nav/DashboardsNavbar";
import Footer from "../../ui/nav/Footer";
import SidebarMarketPlace from "../../ui/nav/SidebarMarketPlace";
const MarketPlaceLayout = ({
  filters,
  setFilters,
  // hideSideMenu,
  // sliderMinMax,
  sidebar = true,
  children,
  user,
  categories,
  clearFilters,
  showSidebar,
}) => {
  // const [sliderValues, setSliderValues] = useState([1, 1000]);

  const handleChange = (name, val) => (evt, newValue) => {
    if (name === "area") {
      // setSliderValues(newValue);
      setFilters({ ...filters, [name]: newValue });
    } else if (val) {
      setFilters({ ...filters, [name]: val.value });
    } else {
      setFilters({ ...filters, [name]: evt.target.value });
    }
  };

  return (
    <div>
      <DashboardsNavbar
        showSidebar={true}
        setShowSidebar={() => {}}
        isMarketPlace={true}
        marketUser={user}
      />
      {sidebar && (
        <SidebarMarketPlace
          filters={filters}
          handleChange={handleChange}
          sliderValues={filters.area}
          categories={categories}
          clearFilters={clearFilters}
          marketUser={user}
          showSidebar={showSidebar}
        />
      )}
      <div className={`${sidebar && "lg:ml-64"} flex  flex-col  w-auto`}>
        {/* <Outlet /> */}
        {children}
        <Footer />
      </div>

      {/* */}
    </div>
  );
};

export default MarketPlaceLayout;
