import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainButton from "../buttons/MainButton";

const Modal = ({ setVisibility, visibility, action, title, children }) => {
  if (visibility)
    return (
      <div
        exit="initial"
        className="overflow-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none no-scrollbar "
        style={{ zIndex: 998 }}
      >
        <div
          className="opacity-50 absolute  bg-black cursor-pointer"
          onClick={() => setVisibility(false)}
          style={{ zIndex: 998, top: 0, bottom: 0, left: 0, right: 0 }}
        ></div>
        <div
          className="relative w-auto my-6 mx-auto max-w-3xl bg-white rounded-lg shadow-lg"
          style={{ zIndex: 999 }}
        >
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full h-screen sm:h-auto bg-white outline-none focus:outline-none sm:min-w-32rem px-10  "
            style={{ minHeight: "452.75px", maxHeight: "640px" }}
          >
            <button
              className="py-3 ml-auto bg-transparent border-0 text-gray-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setVisibility(false)}
            >
              <CloseIcon />
            </button>
            {title && (
              <div className="flex items-start justify-start p-4 rounded-t pointer-events-none">
                <div className="absolute left-20 top-5 mx-10"></div>

                <h3 className="text-3xl font-semibold text-textTitle">
                  {title}
                </h3>
              </div>
            )}

            <div
              className={`relative p-4 flex-auto overflow-hidden overflow-y-auto`}
            >
              {children}
            </div>
            {action && (
              <div className="w-full bg-white p-4 ">
                <MainButton title="Save" action={action} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  return null;
};

export default Modal;
