import React from "react";
import CopyRight from "../ui/pageElementes/Copyright";
export default function Container({ children }) {
  return (
    <div className="w-full sm:w-510 px-4 flex justify-center flex-col">
      {children}
      <CopyRight />
    </div>
  );
}
