import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import ClientsList from "../../Components/ui/pageElementes/ClientsList";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartners,
  addPartner,
  //   searchUsers,
} from "../../redux/modules/admin/actions";
import { useForm } from "react-hook-form";
import Modal from "../../Components/ui/pageElementes/Modal";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import SearchField from "../../Components/ui/pageElementes/SearchField";
import Pagination from "../../Components/ui/pageElementes/Pagination";
import { useModalContext } from "../../SuccessModalContext";

const Partners = () => {
  const {
    handleSubmit,

    control,

    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { partners, error } = useSelector((state) => state.adminData);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  //   const [newArchitectDetails, setNewArchitectDetails] = useState({
  //     name: "",
  //     email: "",
  //     phone: "",
  //     password: "",
  //   });
  const [showModal, setShowModal] = useState(false);

  const { setOpenState } = useModalContext();

  const callback = () => {
    dispatch(fetchPartners({ search: "" }));
    setOpenState(true);
    setShowModal(false);
  };
  const submit = (data) => {
    dispatch(addPartner(data, callback));
    // if (createdArchitectUser.user) {
    //   setNewArchitectDetails({
    //     ...newArchitectDetails,
    //     name: createdArchitectUser.user.name,
    //     email: createdArchitectUser.user.email,
    //     phone: createdArchitectUser.user.phone,
    //     password: createdArchitectUser.user.password,
    //   });
    // }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchPartners({ search: "" }));
  }, []);

  const search = (input) => {
    setSearchValue(input);
    setPage(1);

    dispatch(fetchPartners({ search: input, page: 0 }));
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(fetchPartners({ search: searchValue, page: page - 1 }));
    } else {
      dispatch(fetchPartners({ page: page - 1 }));
    }
  };
  //   function validatePhone(phone) {
  //     return !users.users.some((user) => user.phone === phone);
  //   }

  //   function validateEmail(email) {
  //     return !users.users.some((user) => user.email === email);
  //   }
  const backendErrors = () => {
    if (error && error.length > 0)
      return error.map((e) => (
        <li className="text-xs text-red-500 " key={e.code}>
          {e.message}
          <br />
        </li>
      ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => setShowModal(true)}
        buttonTitle="Add partner"
      />
      {/* {newArchitectDetails.name !== "" && (
        <div className="w-full bg-opacity-50 bg-primary p-5 rounded-xl">
          <p>Last reated user email: {newArchitectDetails.email}</p>
          <p>Last reated user password: {newArchitectDetails.password}</p>
          <p>Last reated user phone: {newArchitectDetails.phone}</p>
          <p>Last reated user name: {newArchitectDetails.name}</p>
        </div>
      )} */}
      <SearchField
        action={search}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {partners && (
        <>
          <ClientsList
            data={partners.users}
            admin={true}
            elementRoute={"/partner"}
            partner={true}
          />
          <Pagination
            pageSize={partners.pageSize}
            totalPages={partners.totalPages}
            total={partners.total}
            page={page}
            setPage={handlePage}
          />
        </>
      )}

      <Modal
        title={`Add partner`}
        visibility={showModal}
        setVisibility={setShowModal}
      >
        <form>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
                <span className="text-xs text-red-500">*</span>
              </h3>{" "}
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="email"
                  // type={showPassword ? "text" : "password"}
                  type="email"
                  placeholder={"Email"}
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please use a valid email address",
                    },
                    required: {
                      value: true,
                      message: "Please add the users email",
                    },
                    // validate: {
                    //   checkEmail: (v) =>
                    //     validateEmail(v) || "Email already used",
                    // },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.email && errors.email.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
                <span className="text-xs text-red-500">*</span>
              </h3>{" "}
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="name"
                  // type={showPassword ? "text" : "password"}
                  type="text"
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                    required: {
                      value: true,
                      message: "Please add the users name",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Company name
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <br />
              <span
                className="text-xs text-blue-400"
                style={{ fontSize: "0.6rem" }}
              >
                *Please add the company name
              </span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="company"
                  // type={showPassword ? "text" : "password"}
                  type="text"
                  placeholder={"Company"}
                  rules={{
                    minLength: {
                      value: 3,
                      message: "The min length of the company name is 3",
                    },
                    required: {
                      value: true,
                      message: "Please add the users company name",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.company && errors.company.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field </span>
              <br />
              <span
                className="text-xs text-blue-400"
                style={{ fontSize: "0.6rem" }}
              >
                *Please add the country prefix: +CCXXXXXXXXX
              </span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="phone"
                  placeholder={"+CC XXX XXX XXX"}
                  type="text"
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                    required: {
                      value: true,
                      message: "Please add the users phone number",
                    },
                    // validate: {
                    //   checkPhone: (v) =>
                    //     validatePhone(v) || "Phone number already used",
                    // },
                  }}
                />
              </div>

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
          </div>
          <ul className="w-full flex justify-center  items-center">
            {backendErrors()}
          </ul>
          <div className="w-full p-2">
            <MainButton action={handleSubmit(submit)} title="Add partner" />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Partners;
