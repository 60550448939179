import React from "react";

import DashboardsNavbar from "../../ui/nav/DashboardsNavbar";
import Footer from "../../ui/nav/Footer";

const LandingPageLayout = ({ children, isLandingPage = true }) => {
  return (
    <div className="w-full overflow-x-hidden">
      <DashboardsNavbar
        showSidebar={false}
        isMarketPlace={true}
        isLandingPage={isLandingPage}
      />
      {children}
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
