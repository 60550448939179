import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
// import // errorClear,
// // // fetchMarketModels,
// // removeModelToFavorite,
// // addModelToFavorite,
// "../../../redux/modules/user/actions";
import {
  // updateEndUserData,/
  errorClear,
  // fetchMarketModels,
  removeModelToFavorite,
  addModelToFavorite,
  savaFavoriteNotAuth,
} from "../../../redux/modules/user/actions";
import Pagination from "./Pagination";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import { useNavigate } from "react-router-dom";
import ModelCard from "../cards/ModelCard";
import GettingStartedForm from "../forms/GettingStartedForm";
import Modal from "./Modal";
import EndUserSigninForm from "../forms/EndUserSigninForm";

// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import FavoriteIcon from "@mui/icons-material/Favorite";

const ListElemet = (props) => {
  // const [isFavorite, setIsFavorite] = useState(null);

  // const checkIsFavorite = () => {
  //   if (props.user.favorites && props.user.favorites.length > 0) {
  //     const favorite = props.user.favorites.find((id) => id === props.model.id);
  //     if (favorite) {
  //       setIsFavorite(props.model.id);
  //     } else {
  //       setIsFavorite(null);
  //     }
  //   }
  // };

  return (
    <ModelCard
      showAction={props.showAction}
      isFavorite={props.isFavorite}
      model={props.model}
      link={`/marketplace/${props.model.id}`}
      simpleAction={() =>
        props.showAction
          ? props.addToFavorite(props.isFavorite, props.model.id)
          : props.action(props.model.id)
      }
      aUser={props.user.role}
    />
  );
};

const ModelsListMarket = (props) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { notAuthFavorite } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [showAction, setShowAction] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [singupFormShow, setSingupFormShow] = useState(true);

  React.useEffect(() => {
    dispatch(errorClear());
    if (isAuthenticated && notAuthFavorite) {
      dispatch(
        addModelToFavorite(notAuthFavorite, dispatch(savaFavoriteNotAuth("")))
      );
    }
  }, []);

  const addToFavorite = (isFavorite, modelId) => {
    if (!isFavorite) {
      dispatch(addModelToFavorite(modelId));
    } else {
      dispatch(removeModelToFavorite(modelId));
    }
  };
  // React.useEffect(() => {
  //   if (props.models && props.models.models) {
  //     setListData(
  //       formatData(
  //         props.slice ? props.models.models.slice(0, 6) : props.models.models
  //       )
  //     );
  //   }
  // }, [props.models]);

  // const formatData = (listData) => {
  //   const result = [];

  //   for (let i = 0; i < listData.length; i++) {
  //     const tempArray = [];
  //     tempArray.push(listData[i]);

  //     result.push(tempArray);
  //   }

  //   return result;
  // };

  useEffect(() => {
    if (isAuthenticated) {
      // checkIsFavorite();
      setShowAction(true);
    } else {
      setShowAction(false);
    }
  }, []);
  const actionForNotLoggedIn = (data) => {
    setShowModal(true);
    dispatch(savaFavoriteNotAuth(data));
  };
  return (
    <>
      <div className="w-full flex  justify-center lg:justify-start items-center">
        <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
          {props.models &&
            props.models.models &&
            props.models.models.length > 0 &&
            props.models.models.map((item, i) => {
              const favorite = props.user?.favorites?.find(
                (id) => id === item.id
              );
              return (
                <ListElemet
                  // row={item}
                  // images={images}
                  // key={i}
                  // user={user}
                  // addToFavorite={addToFavorite}
                  // key={index}
                  showAction={showAction}
                  isFavorite={favorite}
                  clients={props.clients}
                  model={item}
                  key={i}
                  images={props.images}
                  user={props.user}
                  addToFavorite={addToFavorite}
                  action={actionForNotLoggedIn}
                />
              );
            })}
        </div>
      </div>
      <Pagination
        pageSize={props.models.pageSize}
        totalPages={props.models.totalPages}
        total={props.models.total}
        page={props.page}
        setPage={props.handlePage}
      />
      <Modal visibility={showModal} setVisibility={setShowModal}>
        {singupFormShow ? (
          <>
            {" "}
            <GettingStartedForm
              title={"Sign up"}
              subtitle={"Sign up to XRETEH and continue viewing the AR models"}
              signupCallback={() => setSingupFormShow(false)}
            />
            <div className="w-full flex justify-evenly my-2">
              <div
                className="text-primary cursor-pointer font-medium text-right"
                onClick={() => setSingupFormShow(false)}
              >
                Already have an account?
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <EndUserSigninForm
              title={"Sign in"}
              subtitle={"Sign in to XRETEH and continue viewing the AR models"}
              callback={() => {
                location.reload();
                setShowModal(false);
              }}
            />
            <div className="w-full flex justify-evenly my-2">
              <div
                className="text-primary cursor-pointer font-medium text-right"
                onClick={() => setSingupFormShow(true)}
              >
                Don&apos;t have an account?
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModelsListMarket;
