import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/login";
import Dashboard from "./Components/layouts/Dashboard";
import AdminDashboard from "./Components/layouts/AdminDashboard";
import { useSelector } from "react-redux";
// import "@material-tailwind/react/tailwind.css";
import "./assets/styles/tailwind.css";

import SignupPage from "./pages/signup";
import MyModels from "./pages/myModels";
import ModelsAdmin from "./pages/adminModels";
import MyClients from "./pages/myClinets";
import UploadModel from "./pages/uploadModel";
import Users from "./pages/adminUsers";
import SingleModel from "./pages/adminSingleModel";
import AdminEndUsers from "./pages/adminEndUsers";
// import AdminDashboardScreen from "./pages/adminDashboard";
// import DashboardScreen from "./pages/dashboard";
// import ErrorDisplayer from "./Components/ui/pageElementes/ErrorDisplayer";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import ProfilePage from "./pages/profile";
import ViewOptionsPage from "./pages/viewArchitectOptions";
import ViewPartnerOptions from "./pages/viewPartnerOptions";
import ViewEndOptions from "./pages/viewEndOptions";
import SingleClient from "./pages/singleClient";
import SingleModelArchitect from "./pages/singleModelArchitect";
import MarketPage from "./pages/marketPlace";

import { ModalContext } from "./SuccessModalContext";
import SuccessModal from "./Components/ui/modals/SuccessModal";
import SingleMarketModel from "./pages/singleMarketModel/SingleMarketModel";
import SingInEndUserPage from "./pages/singInEndUser";
import SignUpEndUserPage from "./pages/singupEndUser";
import MyAccoountEndUserPage from "./pages/myAcountEndUserPage";
import EndUserLayout from "./Components/layouts/EndUserLayout";
import ErrorPage from "./pages/errorPage";
import MyFavoritesEndUser from "./pages/myFavoritesEndUsers";
import MyModelsEndUser from "./pages/myModelsEndUser";

import LandingPage from "./pages/landingPage";
import PrivacyPage from "./pages/privacy";
import TermsPage from "./pages/termsAndConditions";
import PartnerDashboard from "./Components/layouts/PartnerDashboard";
import Partners from "./pages/partners";
import PartnerUsers from "./pages/partnerUsers";
import PartnerSingleUser from "./pages/partnerSingleUser";
import ModelsPartner from "./pages/partnerModels";
import PartnerSingleModel from "./pages/partnerSingleModel";
// import { getCookie } from "./redux/modules/auth/actions";

function Home({ isAuthenticated, role }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated && role === "userA") {
      navigate("/my-models");
    } else if (isAuthenticated && role === "Admin") {
      navigate("/models");
    } else if (isAuthenticated && role === "Partner") {
      navigate("/users");
    } else if (isAuthenticated) {
      navigate("/marketplace");
    } else {
      navigate("/marketplace");
    }
  }, []);
  return <div></div>;
}
function App() {
  const state = useSelector((state) => state.auth);
  const role = state.user.role;

  const [showSuccessModal, setShowSuccessModal] = useState("");

  const PrivateWrapper = ({ auth: { isAuthenticated, role } }) => {
    if (isAuthenticated && role === "userA") {
      return <Dashboard />;
    } else if (isAuthenticated && role === "Admin") {
      return <AdminDashboard />;
    } else if (isAuthenticated && role === "Partner") {
      return <PartnerDashboard />;
    } else if (isAuthenticated && !role) {
      return <EndUserLayout />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // assign interval to a variable to clear it.
  //     const token = getCookie("uac");
  //     if (token) {

  //     }
  //   }, 10000);

  //   return () => clearInterval(intervalId); // This is important
  // }, []);
  return (
    <>
      <SuccessModal
        visibility={showSuccessModal}
        setVisibility={setShowSuccessModal}
        title={
          showSuccessModal &&
          typeof showSuccessModal === "string" &&
          showSuccessModal.length > 0
            ? showSuccessModal
            : "The update operation succeeded"
        }
      />
      <ModalContext.Provider
        value={{
          setOpenState: setShowSuccessModal,
        }}
      >
        <Router>
          <Routes>
            {/* <Route
              path="/end-user-my-account"
              element={<MyAccoountEndUserPage />}
            /> */}
            <Route
              path="/"
              element={
                <Home isAuthenticated={state.isAuthenticated} role={role} />
              }
            />

            <Route path="/landing-page" element={<LandingPage />}></Route>
            <Route path="/privacy" element={<PrivacyPage />}></Route>
            <Route path="/terms" element={<TermsPage />}></Route>

            <Route path="/marketplace" element={<MarketPage />} />
            <Route
              path="/marketplace/:modelId"
              element={<SingleMarketModel />}
            />
            {/* <Route
              path="/market-single-model"
              element={<SingleMarketModel isAuthenticated={false} />}
            /> */}
            <Route path="/signup-end" element={<SignUpEndUserPage />} />
            {/* <Route path="/marketplace" element={<MarketPage />} /> */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/signin" element={<SingInEndUserPage />} />
            <Route
              element={
                <PrivateWrapper
                  auth={{
                    isAuthenticated: state.isAuthenticated,
                    role: role,
                  }}
                />
              }
            >
              <Route path="/my-models" element={<MyModels />} />
              <Route path="/my-clients" element={<MyClients />} />
              <Route path="/my-client/:id" element={<SingleClient />} />
              <Route path="/upload-model" element={<UploadModel />} />
              <Route path="/my-account" element={<ProfilePage />} />
              <Route path="/model/:id" element={<SingleModelArchitect />} />
            </Route>
            {/* <Route
              element={
                <PrivateWrapper
                  auth={{ isAuthenticated: state.isAuthenticated, role: role }}
                />
              }
            >
              <Route path="/marketplace/:userId" element={<MarketPage />} />
              <Route
                path="/market-single-model-logedin/:id"
                element={<SingleMarketModel isAuthenticated={true} />}
              />
            </Route> */}
            {role === "Admin" ? (
              <Route
                element={
                  <PrivateWrapper
                    auth={{
                      isAuthenticated: state.isAuthenticated,
                      role: role,
                    }}
                  />
                }
              >
                <Route path="/models" element={<ModelsAdmin />} />
                <Route path="/architects" element={<Users />} />
                <Route
                  path="/architect-options/:id"
                  element={<ViewOptionsPage />}
                />
                <Route path="/end-users" element={<AdminEndUsers />} />
                <Route
                  path="/end-users-options/:id"
                  element={<ViewEndOptions />}
                />
                <Route path="/models/:id" element={<SingleModel />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/partner/:id" element={<ViewPartnerOptions />} />
              </Route>
            ) : (
              role === "Partner" && (
                <Route
                  element={
                    <PrivateWrapper
                      auth={{
                        isAuthenticated: state.isAuthenticated,
                        role: role,
                      }}
                    />
                  }
                >
                  <Route path="/users" element={<PartnerUsers />} />
                  <Route path="/user/:id" element={<PartnerSingleUser />} />
                  <Route path="/models" element={<ModelsPartner />} />
                  {/* <Route
                    path="/architect-options/:id"
                    element={<ViewOptionsPage />}
                  /> */}
                  {/* <Route path="/end-users" element={<AdminEndUsers />} />
                  <Route
                    path="/end-users-options/:id"
                    element={<ViewEndOptions />}
                  /> */}
                  <Route path="/models/:id" element={<PartnerSingleModel />} />
                </Route>
              )
            )}
            <Route
              element={
                <PrivateWrapper
                  auth={{
                    isAuthenticated: state.isAuthenticated,
                  }}
                />
              }
            >
              {/* <Route path="/marketplace/:userId" element={<MarketPage />} /> */}
              <Route path="/my-profile" element={<MyAccoountEndUserPage />} />
              <Route path="/my-favorites" element={<MyFavoritesEndUser />} />
              <Route path="/my-assigned-models" element={<MyModelsEndUser />} />
            </Route>
            <Route path="*" element={<ErrorPage />}></Route>
          </Routes>
        </Router>
      </ModalContext.Provider>
    </>
  );
}

export default App;
