import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import { fetchModels, searchModels } from "../../redux/modules/models/actions";

import { fetchClients } from "../../redux/modules/clients/actions";
import { useSelector, useDispatch } from "react-redux";
import SearchField from "../../Components/ui/pageElementes/SearchField";
import Pagination from "../../Components/ui/pageElementes/Pagination";
const MyModels = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = React.useState([]);
  const models = useSelector((state) => state.modelData);
  const [filters, setFilters] = useState({
    rooms: "",
    bathrooms: "",
    materials: "",
  });
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchModels());
    dispatch(fetchClients());
    // dispatch(updateUserData());

    // do anything only one time if you pass empty array []
    // keep in mind, that component will be rendered one time (with default values) before we get here
  }, []);
  useEffect(() => {
    if (models?.models) {
      setListData(formatData(models.models.models));
    }
  }, [models]);

  const formatData = (listData) => {
    const result = [];

    if (listData && listData.length > 0)
      for (let i = 0; i < listData.length; i += 4) {
        const tempArray = [];

        if (listData[i]) tempArray.push(listData[i]);
        if (listData[i + 1]) tempArray.push(listData[i + 1]);
        if (listData[i + 2]) tempArray.push(listData[i + 2]);
        if (listData[i + 3]) tempArray.push(listData[i + 3]);

        result.push(tempArray);
      }

    return result;
  };

  const search = (input, filters) => {
    setPage(1);
    dispatch(searchModels(input, filters, 0));
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(searchModels(searchValue, filters, page - 1));
    } else {
      dispatch(fetchModels(page - 1));
    }
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={HomeOutlinedIcon}
        link="/upload-model"
        buttonTitle="Upload new model"
      />
      <SearchField
        action={search}
        showFilterButton={true}
        setFilters={setFilters}
        filters={filters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <ModelsList listData={listData} extraLink={"/model"} />
      <Pagination
        pageSize={models.models.pageSize}
        totalPages={models.models.totalPages}
        total={models.models.total}
        page={page}
        setPage={handlePage}
      />
    </div>
  );
};

export default MyModels;
