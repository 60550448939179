import React, { useEffect, useState } from "react";
// import background from "../../staticfiles/background.png";
// import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../staticfiles/Logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { updateUserData } from "../../redux/modules/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { signup, clearError } from "../../redux/modules/auth/actions";
import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import MainButton from "../../Components/ui/buttons/MainButton";
import { Controller, useForm } from "react-hook-form";
const SignupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const { user, isAuthenticated, error } = useSelector((state) => state.auth);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    // weight: "",
    // weightRange: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const signinCallback = (user) => {
    dispatch(updateUserData());
    if (user.role === "Admin") {
      navigate("/models");
    } else if (user.role === "userA") {
      navigate("/my-models");
    }
  };
  const submitSignup = (data) => {
    dispatch(
      signup(data.email, data.password, data.name, data.phone, signinCallback)
    );
  };
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "Admin") {
        navigate("/models");
      } else if (user.role === "userA") {
        navigate("/my-models");
      }
    }
    dispatch(clearError());
  }, []);
  useEffect(() => {
    if (error && error.length > 0) {
      setShowErrors(true);
    }
  }, [error]);
  const errorDisplay = () => {
    if (error.length > 0) {
      return error.map((error, i) => {
        return (
          <p
            key={i}
            className="text-xs text-red-500 "
            style={{ minHeight: "1rem" }}
          >
            {error.message}
          </p>
        );
      });
    }
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  return (
    <Page>
      <Container className="flex items-center">
        <a href="https://xreteh.com">
          <img
            src={logo}
            style={{ margin: "0 auto" }}
            className="logo h-20 w-48 sm:h-56 sm:w-72"
          />
        </a>

        <div className="z-10 w-full bg-white sm:mt-5 p-2 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <div className="py-7">
            <h2 className="font-bold text-center text-black py-2.5">
              Getting Started
            </h2>
            <p className="font-medium text-center text-fade leading-7 tracking-wide">
              Sign up to XRETEH and continue
            </p>
          </div>
          <form className="form flex flex-col justify-between items-center w-full">
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
              <PersonIcon />

              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <input
                      onChange={(e) => onChange(e)}
                      placeholder="Name"
                      className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                    />
                  );
                }}
                name="name"
                rules={{
                  minLength: {
                    value: 4,
                    message: "The min length of the name is 4",
                  },
                  required: {
                    value: true,
                    message: "Your name is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.name && errors.name.message}
            </p>
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
              <PhoneIphoneIcon />

              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <input
                      onChange={(e) => onChange(e)}
                      placeholder="Phone"
                      className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                    />
                  );
                }}
                name="phone"
                rules={{
                  pattern: {
                    value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                    message: "Please use a valid phone number",
                  },
                  required: {
                    value: true,
                    message: "Please add a phone number",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.phone && errors.phone.message}
            </p>
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
              <MailOutlineIcon />

              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <input
                      onChange={(e) => onChange(e)}
                      placeholder="Email"
                      className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                    />
                  );
                }}
                name="email"
                rules={{
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    message: "Please use a valid email address",
                  },
                  required: {
                    value: true,
                    message: "Please add an email address",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.email && errors.email.message}
            </p>
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
              <LockOutlinedIcon />
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <>
                      <input
                        type={values.showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={(e) => onChange(e)}
                        className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                      />
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </>
                  );
                }}
                name="password"
                rules={{
                  minLength: {
                    value: 4,
                    message: "The min length of the name is 4",
                  },
                  required: {
                    value: true,
                    message: "The password is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.password && errors.password.message}
            </p>
            <div className="w-full flex justify-evenly my-2">
              <Link
                className="text-primary font-medium text-center sm:text-right"
                to="/login"
              >
                Already have an account?
              </Link>

              {/* <p className="text-primary font-medium text-right">
                {" "}
                Forgot password?{" "}
              </p> */}
            </div>
            {showErrors && error.length > 0 && errorDisplay()}
            <div className="flex justify-center w-full my-2">
              <MainButton
                title="Enter the virtual world"
                action={handleSubmit(submitSignup)}
                width="w-full sm:w-5/6"
              />
            </div>
          </form>
        </div>
      </Container>
    </Page>
  );
};

export default SignupPage;
