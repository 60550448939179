import {
  ADMIN_FETCH_USERS,
  ADMIN_ERROR,
  ADMIN_SUCCESS,
  ADMIN_LOADING,
  ADMIN_FETCH_MODELS,
  ADMIN_FETCH_SINGLE_MODEL,
  ADMIN_CREATE_ARCHITECT,
  ADMIN_FETCH_END_USERS,
  ADMIN_ERROR_CLEAR,
  ADMIN_FETCH_SINGLE_END_USER,
  ADMIN_FETCH_SINGLE_USER,
  ADMIN_FETCH_USERS_SEARCH,
  ADMIN_FETCH_LATEST_USERS,
  DUPLICATE_MODEL,
  FETCH_PARTNERS,
} from "./constants";

export const initialState = {
  singleModel: {},
  createdArchitectUser: {},
  users: {},
  partners: {},
  latestUsers: {},
  endUsers: {},
  models: {},
  singelUser: {},
  singleEndUser: {},
  duplicatedModel: {},
  loading: false,
  error: [],
  success: "",
};

const error = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false,
  };
};

const loading = (state, action) => {
  return {
    ...state,
    loading: true,
    error: [],
  };
};
const success = (state, action) => {
  return {
    ...state,
    loading: false,
    error: [],
    success: action.payload,
  };
};
const duplicateModelSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: [],
    duplicatedModel: action.payload,
  };
};

const fetchUsersSuccess = (state, action) => {
  return {
    ...state,
    users: action.payload,
    error: [],
    singelUser: {},
    singleEndUser: {},
    loading: false,
  };
};
const fetchLatestUsersSuccess = (state, action) => {
  return {
    ...state,

    latestUsers: action.payload,
    error: [],
    singelUser: {},
    singleEndUser: {},
    loading: false,
  };
};
const fetchUsersSearchSuccess = (state, action) => {
  return {
    ...state,
    users: action.payload,
    error: [],
    singelUser: {},
    singleEndUser: {},
    loading: false,
  };
};

const fetchModelsSuccess = (state, action) => {
  return {
    ...state,
    models: action.payload,
    singleModel: {},
    error: [],
    loading: false,
  };
};
const fetchSingleModelsSuccess = (state, action) => {
  return {
    ...state,
    singleModel: action.payload,
    duplicatedModel: {},
    error: [],
    loading: false,
  };
};
const fetchSingleEndSuccess = (state, action) => {
  return {
    ...state,
    singleEndUser: action.payload,
    error: [],
    loading: false,
  };
};
const fetchEndUsersSuccess = (state, action) => {
  return {
    ...state,
    endUsers: action.payload,
    error: [],
    loading: false,
  };
};
const createdArchitectUserSuccess = (state, action) => {
  return {
    ...state,
    createdArchitectUser: action.payload,
    error: [],
    loading: false,
  };
};
const fetchSingleUserSuccess = (state, action) => {
  return {
    ...state,
    singelUser: action.payload,
    error: [],
    loading: false,
  };
};
const fetchPartnersSuccess = (state, action) => {
  return {
    ...state,
    partners: action.payload,
    error: [],
    loading: false,
  };
};
// const createClientsSuccess = (state, action) => {
//   return {
//     ...state,
//     hasError: false,
//     isLoading: false,
//     success: action.payload,
//   };
// };
function errorClear(state) {
  const newState = { ...state };

  newState.error = [];
  return newState;
}
const handlers = {
  [ADMIN_ERROR]: error,
  [ADMIN_LOADING]: loading,
  [ADMIN_SUCCESS]: success,
  [ADMIN_FETCH_USERS]: fetchUsersSuccess,
  [ADMIN_FETCH_MODELS]: fetchModelsSuccess,
  [ADMIN_FETCH_SINGLE_MODEL]: fetchSingleModelsSuccess,
  [ADMIN_CREATE_ARCHITECT]: createdArchitectUserSuccess,
  [ADMIN_FETCH_END_USERS]: fetchEndUsersSuccess,
  [ADMIN_FETCH_SINGLE_USER]: fetchSingleUserSuccess,
  [ADMIN_ERROR_CLEAR]: errorClear,
  [ADMIN_FETCH_SINGLE_END_USER]: fetchSingleEndSuccess,
  [ADMIN_FETCH_USERS_SEARCH]: fetchUsersSearchSuccess,
  [ADMIN_FETCH_LATEST_USERS]: fetchLatestUsersSuccess,
  [DUPLICATE_MODEL]: duplicateModelSuccess,
  [FETCH_PARTNERS]: fetchPartnersSuccess,
};

export default function adminData(state = initialState, action) {
  const handler = handlers[action.type];

  if (!handler) return state;
  return handler(state, action);
}
