import React, { useState, useEffect } from "react";
import {
  assignModel,
  removeAssignment,
} from "../../../redux/modules/models/actions";
import { updateUserData } from "../../../redux/modules/user/actions";
import {
  fetchClients,
  searchClients,
} from "../../../redux/modules/clients/actions";

import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";
import FaceIcon from "@mui/icons-material/Face";
import CheckBoxFull from "../primitives/CheckboxFull";
import CheckBox from "../primitives/Checkbox";
import SearchField from "./SearchField";
import { useModalContext } from "../../../SuccessModalContext";
import ModelCard from "../cards/ModelCard";

const ListElemet = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const callbackAssignment = () => {
    dispatch(updateUserData());
    dispatch(fetchClients(0, props.clients.total));
    setOpenState(true);
  };

  const [alreadyChecked, setAlreadyChecked] = useState([]);
  const [unchecked, setUchecked] = useState([]);

  const { setOpenState } = useModalContext();

  const handleClickAssignments = (userId, checked) => {
    const index = alreadyChecked.indexOf(userId);
    if (index > -1) {
      setAlreadyChecked((prev) => prev.filter((e) => e !== userId));
      setUchecked([...unchecked, userId]);
    } else {
      setAlreadyChecked([...alreadyChecked, userId]);
      setUchecked((prev) => prev.filter((e) => e !== userId));
    }
  };
  useEffect(() => {
    if (showModal) {
      setAlreadyChecked([]);
      setUchecked([]);
      const tempArray = [];
      props.clients.users.map((item, i) => {
        let checked;

        if (props.model.id) {
          checked =
            item.models3D.filter((e) => e.id === props.model.id).length > 0;
        } else {
          checked = item.models3D.includes(props.model.id);
        }

        if (checked) {
          tempArray.push(item.id);
        }
      });
      setAlreadyChecked(tempArray);
    }
  }, [showModal, props.clients]);

  const submit = () => {
    dispatch(
      assignModel(
        {
          currentUser: props.model.userId.id,
          modelId: props.model.id,
          endUserIds: alreadyChecked,
        },
        callbackAssignment
      )
    );
    dispatch(
      removeAssignment(
        {
          currentUser: props.model.userId.id,
          modelId: props.model.id,
          endUserIds: unchecked,
        },
        callbackAssignment
      )
    );
    setShowModal(false);
  };
  const search = (input) => {
    setSearchValue(input);

    if (input === "" || input === undefined) {
      dispatch(fetchClients(0, props.clients.total));
    } else {
      dispatch(searchClients(input, 0));
    }
  };
  return (
    <div className="flex justify-center items-center">
      <ModelCard
        key={props.index}
        model={props.model}
        link={
          props.extraLink
            ? `${props.extraLink}/${props.model.id}`
            : `/models/${props.model.id}`
        }
        modifiable={false}
        noAction={true}
        // modalAction={() => setShowModal(true)}
      />
      {/* <div
        className={
          "relative w-full flex items-center justify-evenly flex-col bg-white rounded-xl shadow-md   overflow-hidden"
        }
      >
        <div className={"absolute  h-10 top-5 right-5 "}>
          <MainButton
            Icon={<AddIcon className="text-white" />}
            action={() => setShowModal(true)}
            title="Assign this model"
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
          />
        </div>
        <Link to={`/model/${props.model.id}`} className="w-full">
          <div className={" w-full"}>
            <div className="w-full h-52">
              <img
                src={`${process.env.REACT_APP_API}/models3D/content/image/${props.model.id}/${props.model.updatedAt}`}
                className="object-cover h-52 w-full"
                alt={props.model.name}
              />
            </div>

            <div
              className={
                "absolute w-10 h-10 top-5 left-5 rounded-full overflow-hidden bg-primary flex justify-center items-center"
              }
            >
              {props.model.userId?.profilePicture ? (
                <img
                  className="w-full object-cover h-10 "
                  src={`${process.env.REACT_APP_API}/content/${
                    props.model.userId.id
                  }/${new Date().getTime()}`}
                  alt={props.model.userId.company}
                />
              ) : (
                <FaceIcon className="text-white " />
              )}
            </div>
            <div
              className={" text-white text-m font-bold w-full text-center"}
              style={{ background: "rgba(0, 0, 0, 0.4)" }}
            >
              <span>{props.model?.name}</span>
            </div>
          </div>
          <div className={"flex justify-evenly items-center p-2 w-full"}>
            <span className={"flex justify-center items-center"}>
              <BedIcon />
              <span>{props.model?.rooms}</span>
            </span>
            <span className={"flex justify-center items-center"}>
              <BathroomIcon />
              <span>{props.model?.bathrooms}</span>
            </span>
            <span className={"flex justify-center items-center"}>
              <CropIcon />
              <span>{props.model?.area}</span>
            </span>
            <span className={"flex justify-center items-center"}>
              <span>
                {props.model?.arReady ? (
                  <ViewInAr className="text-green-500" />
                ) : (
                  <ViewInAr className="text-gray-500 line-through" />
                )}
              </span>
            </span>
          </div>
        </Link>
      </div> */}

      <Modal
        title={`Assign model`}
        visibility={showModal}
        setVisibility={setShowModal}
        action={() => submit()}
      >
        <SearchField
          action={search}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          width="w-full"
        />
        {props.clients.users &&
          props.clients.users.length > 0 &&
          props.clients.users.map((item, i) => {
            const checked = alreadyChecked.includes(item.id);

            return (
              <div
                className={`w-full flex items-center my-2 p-2 relative cursor-pointer ${
                  checked && "bg-primary bg-opacity-20"
                }`}
                key={i}
                onClick={() => handleClickAssignments(item.id, checked)}
              >
                <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full">
                  {item.profilePicture ? (
                    <img
                      src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                    />
                  ) : (
                    <FaceIcon className="text-white" />
                  )}
                </div>
                <div className="text-sm font-medium text-gray-900 ml-4">
                  {item.name}
                  <div className="text-sm text-gray-500">
                    {item.phone} {item.email}
                  </div>
                </div>
                {checked ? (
                  <div className="absolute right-0">
                    <CheckBoxFull />
                  </div>
                ) : (
                  <div className="absolute right-0">
                    <CheckBox />
                  </div>
                )}
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

const ListRow = (props) => {
  if (props.row && props.row.length > 0) {
    return props.row.map((listElement, index) => {
      return (
        <ListElemet
          clients={props.clients}
          modalShow={props.modalShow}
          model={listElement}
          images={props.images}
          key={index}
          extraLink={props.extraLink}
        />
      );
    });
  }
  return <></>;
};

const ModelsList = (props) => {
  const [images] = React.useState([]);
  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state.clientsData);
  React.useEffect(() => {
    dispatch(fetchClients());
  }, []);

  return (
    <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
      {props.listData &&
        props.listData.length > 0 &&
        props.listData.map((item, i) => {
          return (
            <ListRow
              key={i}
              clients={clients}
              row={item}
              images={images}
              modalShow={props.modalShow}
              extraLink={props.extraLink}
            />
          );
        })}
    </div>
  );
};

export default ModelsList;
