import React, { useState, useEffect } from "react";
import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import useInterval from "../../hooks/useInterval";
const RemoveModal = ({
  setVisibility,
  visibility,
  title,
  description,
  submitAction,
  buttonText,
}) => {
  const [showTimesUp, updateShowTimesUp] = useState(false);
  const hideModal = () => {
    setVisibility(false);
    updateShowTimesUp(false);
  };
  const submit = () => {
    submitAction();
    hideModal();
  };
  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="w-full text-center flex flex-col justify-center items-center">
          <CancelOutlinedIcon className="text-red-500" sx={{ fontSize: 131 }} />
        </div>
        <h3>{title}</h3>
        <p className="flex  align-center text-center justify-center">
          {" "}
          {description}
        </p>
        <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 px-2">
            <MainButton
              action={() => hideModal()}
              title="Cancel"
              width="w-full "
            />
          </div>
          <div className="w-full sm:w-1/2 p-2">
            <MainButton
              alert={showTimesUp}
              disabled={!showTimesUp}
              action={() => submit()}
              title={
                !showTimesUp ? (
                  <div className="text-sm">
                    {buttonText || "Remove"}(
                    <span className="text-xs">
                      <Countdown
                        countFrom={5}
                        onComplete={() => updateShowTimesUp(true)}
                      />
                    </span>
                    )
                  </div>
                ) : (
                  buttonText || "Remove"
                )
              }
              width="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
function Countdown(props) {
  const [countDown, updateCountDown] = useState(props.countFrom);
  const stop = useInterval({
    duration: 1000,
    callback: () => {
      updateCountDown((prevCountDown) => prevCountDown - 1);
    },
  });
  useEffect(() => {
    if (!countDown) {
      stop(); // try commenting this to confirm the cleanup on unmount
      props.onComplete();
    }
  }, [countDown, props.onComplete]);

  return countDown;
}
export default RemoveModal;
