import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Statistics from "../../ui/pageElementes/Statistics";
import { fetchLatestClients } from "../../../redux/modules/clients/actions";
import Sidebar from "../../ui/nav/Sidebar";
import Footer from "../../ui/nav/Footer";
const Dashboard = () => {
  const [useUsers, setUseUsers] = useState([]);
  const dispatch = useDispatch();
  const {
    user: { role },
  } = useSelector((state) => state.auth);
  const { latestClients } = useSelector((state) => state.clientsData);
  useEffect(() => {
    dispatch(fetchLatestClients());
  }, []);
  useEffect(() => {
    if (latestClients.users && latestClients.users.length > 0) {
      const arr = latestClients.users.slice(0, 5);
      setUseUsers(arr);
    }
  }, [latestClients]);

  return (
    <div className="p-2">
      <Sidebar />
      <div className="lg:ml-64 ">
        <div className="flex flex-col xl:flex-row w-auto">
          <Outlet />

          <div className="flex flex-col md:flex-row xl:flex-col justify-start items-start">
            <Statistics
              users={useUsers}
              title={
                role === "Admin" ? "Latest created architects" : "Your clients"
              }
            />
            {/* <Analytics /> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
