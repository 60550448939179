import {
  UPLOAD_MODEL_HAS_ERROR,
  UPLOAD_MODEL_LOADING,
  UPLOAD_MODEL_SUCCESS,
  FETCH_MODELS_LOADING,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_HAS_ERROR,
  ASSIGN_MODEL,
  REMOVE_ASSIGNMENT,
  MODELS_ERROR_CLEAR,
  UPDATE_MODEL_SUCCESS,
  UPDATE_MODEL_COVER_SUCCESS,
  FETCH_SINGLE_MODEL_SUCCESS,
  DELETE_MODEL_SUCCESS,
  GET_CATEGORIES_SECOND,
  GET_CATEGORIES_PARRENT,
  ADD_MODEL_TO_FAVORITE_ERROR,
  // ADD_MODEL_TO_FAVORITE_SUCCESS,
  ADD_MODEL_TO_FAVORITE_LOADING,
  // REMOVE_MODEL_TO_FAVORITE_LOADING,
  FETCH_END_USER_FAVORITES,
  FETCH_END_USER_MODELS,
} from "./constants";
import axios from "axios";
import cookie from "js-cookie";
import clearAllStoreData from "../../resetAction";

export function modelLoadingError(data) {
  return {
    type: UPLOAD_MODEL_HAS_ERROR,
    payload: data,
  };
}

export function modelLoading() {
  return {
    type: UPLOAD_MODEL_LOADING,
  };
}

export function modelLoadingSuccess() {
  return {
    type: UPLOAD_MODEL_SUCCESS,
  };
}
export function modelUpdateSuccess() {
  return {
    type: UPDATE_MODEL_SUCCESS,
  };
}
export function coverImageUpdateSuccess() {
  return {
    type: UPDATE_MODEL_COVER_SUCCESS,
  };
}
export function deleteModelSuccess() {
  return {
    type: DELETE_MODEL_SUCCESS,
  };
}
export function errorClear() {
  return {
    type: MODELS_ERROR_CLEAR,
  };
}
export function uploadModel(modelData, callback, onError) {
  return (dispatch) => {
    dispatch(modelLoading());
    const token = getCookie("uac");

    axios
      .post(`${process.env.REACT_APP_API}/models3D`, modelData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(modelLoadingSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(modelLoadingError(err.response.data.errors));
          onError();
        }
      });
  };
}
export function updateModel(id, modelData, callback) {
  return (dispatch) => {
    dispatch(modelLoading());
    const token = getCookie("uac");
    axios
      .put(`${process.env.REACT_APP_API}/models3D/${id}`, modelData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(modelUpdateSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(modelLoadingError(err.response.data.errors));
        }
      });
  };
}

export function addModelToFavoriteLoading() {
  return {
    type: ADD_MODEL_TO_FAVORITE_LOADING,
  };
}

export function addModelToFavoriteError() {
  return {
    type: ADD_MODEL_TO_FAVORITE_ERROR,
  };
}
export function getEndUserFavorites(data) {
  return {
    type: FETCH_END_USER_FAVORITES,
    payload: data,
  };
}

export function deleteModel(id, callback) {
  return (dispatch) => {
    dispatch(modelLoading());
    const token = getCookie("uac");
    axios
      .delete(`${process.env.REACT_APP_API}/models3D/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(deleteModelSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(modelLoadingError(err.response.data.errors));
        }
      });
  };
}

export function fetchModelsError(data) {
  return {
    type: FETCH_MODELS_HAS_ERROR,
    payload: data,
  };
}

export function fetchModelsLoading() {
  return {
    type: FETCH_MODELS_LOADING,
  };
}
export function fetchCategoriesSecondSuccess(data) {
  return {
    type: GET_CATEGORIES_SECOND,
    payload: data,
  };
}
export function fetchParrentCategoriesSuccess(data) {
  return {
    type: GET_CATEGORIES_PARRENT,
    payload: data,
  };
}
export function fetchModelsSuccess(data) {
  return {
    type: FETCH_MODELS_SUCCESS,
    payload: data,
  };
}
export function fetchModelsEndUserSuccess(data) {
  return {
    type: FETCH_END_USER_MODELS,
    payload: data,
  };
}

export function fetchSingleModelSuccess(data) {
  return {
    type: FETCH_SINGLE_MODEL_SUCCESS,
    payload: data,
  };
}

export function assignModelSuccess(data) {
  return {
    type: ASSIGN_MODEL,
    payload: data,
  };
}
export function removeAssignmentSuccess(data) {
  return {
    type: REMOVE_ASSIGNMENT,
    payload: data,
  };
}

export const getCookie = (key) => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export function fetchModels(page, limit) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/models3D/a-user${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchModelsEndUser({
  search,
  filters,
  page,
  limit,
  sortBy,
  sortValue,
}) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/end-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}${sortBy ? "&sortBy=" + sortBy : ""}${
          sortValue ? "&sortValue=" + sortValue : ""
        }`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsEndUserSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function getCategoriesSecond(page, limit) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/categories/second${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchCategoriesSecondSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function getParrentCategories(page, limit) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/categories/parrents${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchParrentCategoriesSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function getChildCategories(id, page, limit) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    const query = `${page ? "?page=" + page : "?page=" + 0}${
      limit ? "&limit=" + limit : ""
    }`;
    axios
      .get(`${process.env.REACT_APP_API}/categories/childs/${id}${query}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchCategoriesSecondSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function searchModels(search, filters, page, limit) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/a-user/search?page=${page}${
          limit ? "&limit=" + limit : ""
        }&search=${search}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchSingleModel(id) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .get(`${process.env.REACT_APP_API}/models3D/single/${id}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchSingleModelSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchSingleModelMarketPlace(id) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    axios
      .get(`${process.env.REACT_APP_API}/models3D/marketplace/${id}`, {})
      .then((response) => {
        dispatch(fetchSingleModelSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function assignModel(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/a-users/assignments`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(assignModelSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function assignModelsToSingleUser(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    axios
      .post(`${process.env.REACT_APP_API}/a-users/assignments-mutiple`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(assignModelSuccess(response.data));

        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function removeAssignment(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(`${process.env.REACT_APP_API}/a-users/assignments/remove`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(removeAssignmentSuccess(response.data));
        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function removeAssignmentToSingleUser(data, callback) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    axios
      .post(
        `${process.env.REACT_APP_API}/a-users/assignments-multiple/remove`,
        data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(removeAssignmentSuccess(response.data));

        if (callback) callback();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export const coverImageUpdate =
  (id, formData, callback) => async (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");
    // change url when you have it
    axios
      .put(
        `${process.env.REACT_APP_API}/models3D/cover-image/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(coverImageUpdateSuccess(response.data));
        if (callback) callback();
      })

      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };

export function fetchMarketModels({
  sortBy,
  sortValue,
  search,
  filters,
  page,
  limit,
}) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    const query = `${page ? "page=" + page : "page=" + 0}${
      limit ? "&limit=" + limit : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}${
      sortValue ? "&sortValue=" + sortValue : ""
    }${search ? "&search=" + search : ""}`;

    axios
      .post(
        `${process.env.REACT_APP_API}/models3D/marketplace?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(fetchModelsSuccess(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}
export function fetchFavorites({
  sortBy,
  sortValue,
  search,
  filters,
  page,
  limit,
}) {
  return (dispatch) => {
    dispatch(fetchModelsLoading());
    const token = getCookie("uac");

    const query = `${page ? "page=" + page : "page=" + 0}${
      limit ? "&limit=" + limit : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}${
      sortValue ? "&sortValue=" + sortValue : ""
    }${search ? "&search=" + search : ""}`;

    axios
      .post(
        `${process.env.REACT_APP_API}/end-users/favorites?${query}`,
        filters,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getEndUserFavorites(response.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(clearAllStoreData());
        } else {
          if (err.response && err.response.data)
            dispatch(fetchModelsError(err.response.data.errors));
        }
      });
  };
}

export const clearError = () => async (dispatch) => {
  dispatch(errorClear());
};
