import React from "react";
import { useLocation } from "react-router-dom";
import BackButton from "../buttons/BackButton";
import MainButton from "../buttons/MainButton";
import SecondaryButton from "../buttons/SecondaryButton";

const PageHeader = ({
  Icon,
  Profile,
  link,
  buttonTitle,
  action,
  title,
  buttonSecondary,
  buttonIcon,
  iconColor,
  subtitle,
  backLink,
}) => {
  const location = useLocation().pathname;
  return (
    <>
      {backLink && <BackButton link={backLink} />}
      <div className="shadowBox my-4 flex px-6 items-center justify-between border-white border-2 w-full rounded-lg sm:p-5 ">
        <div
          className={
            "flex justify-center sm:justify-around items-center m-2 flex-col sm:flex-row"
          }
        >
          <span
            className={`text-lg sm:text-5xl ${iconColor || "bg-primary"} ${
              !Profile && "p-2 sm:p-5"
            } h-12 w-12 flex justify-center items-center text-white rounded-full overflow-hidden`}
          >
            {Profile || <Icon />}
          </span>

          <div className={"m-2 flex items-start flex-col"}>
            <div
              className={
                "text-textTitle text-xs sm:text-xl font-bold text-center"
              }
            >
              {title ||
                (location === "/"
                  ? "DASHBOARD"
                  : location
                      .toLocaleUpperCase()
                      .replace("/", "")
                      .replace("-", " "))}
            </div>
            <p className="text-xs text-gray-500">{subtitle}</p>
          </div>
        </div>

        <div className={"flex justify-evenly items-center mx-2"}>
          <div className={"profileHeader-menuButtonContainer"}>
            {buttonTitle &&
              (!buttonSecondary ? (
                <MainButton
                  link={link}
                  action={action}
                  title={buttonTitle}
                  Icon={buttonIcon}
                />
              ) : (
                <SecondaryButton
                  link={link}
                  action={action}
                  title={buttonTitle}
                  Icon={buttonIcon}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
