import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleModel,
  // downloadSingleModel,
  // uploadFinalModel,
  fetchModels,
  // fetchEndUsers,
  // searchEndUsers,
  // searchUsers,
  // fetchUsers,
  // duplicateModelAction,
} from "../../redux/modules/partners/actions";
import {
  // fetchSingleModel,
  downloadSingleModel,
  uploadFinalModel,
  // fetchModels,
  // fetchEndUsers,
  // searchEndUsers,
  // searchUsers,
  // fetchUsers,
  // duplicateModelAction,
} from "../../redux/modules/admin/actions";
import {
  // assignModel,
  // removeAssignment,
  updateModel,
  coverImageUpdate,
  deleteModel,
  getParrentCategories,
  getChildCategories,
} from "../../redux/modules/models/actions";
import { updateUserData } from "../../redux/modules/user/actions";
// import { fetchClients } from "../../redux/modules/clients/actions";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import {
  Switch,
  TextareaAutosize,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
// import FaceIcon from "@mui/icons-material/Face";
import { useForm, Controller } from "react-hook-form";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import SecondaryButton from "../../Components/ui/buttons/SecondaryButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveModal from "../../Components/ui/modals/RemoveModal";

import { useModalContext } from "../../SuccessModalContext";
// import CheckBoxFull from "../../Components/ui/primitives/CheckboxFull";
// import CheckBox from "../../Components/ui/primitives/Checkbox";
// import SearchField from "../../Components/ui/pageElementes/SearchField";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
import Modal from "../../Components/ui/pageElementes/Modal";
const PartnerSingleModel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [filesModel, setFilesModel] = React.useState([]);
  const [filesCoverImageZon, setFilesCoverImageZone] = React.useState([]);
  const [parrentCategoryValues, setParrentCategoryValues] = React.useState("");
  const [formData, setFormData] = useState(new FormData());
  const [coverFormData, setCoverFormData] = useState(new FormData());
  const { singleModel } = useSelector((state) => state.partnersData);
  const { error, parrentCategories, categories } = useSelector(
    (state) => state.modelData
  );

  // const [alreadyChecked, setAlreadyChecked] = useState([]);
  // const [unchecked, setUchecked] = useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [userSearchValue, setUserSearchValue] = useState("");
  // const [totalUsers, setTotalUsers] = useState(12);
  const [finalResponse, setFinalResponse] = React.useState({
    coverImage: [], // img
    material: {}, // file
    textures: "", // img
    name: "",
    otherDetail: "",
    description: "",
    rooms: "",
    bathrooms: "",
    area: "",
    materials: "",
    marketplace: false,
    numberOfFloors: 1,
    floorHeight: 1,
  });

  const { setOpenState } = useModalContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchSingleModel(id));
    // dispatch(fetchEndUsers(0, endUsers.total));
    dispatch(getParrentCategories());

    setFormData(new FormData());
    setCoverFormData(new FormData());
    // dispatch(fetchUsers());
    // setTotalUsers(users.total);
  }, []);

  // useEffect(() => {
  //   setAlreadyChecked([]);
  //   setUchecked([]);
  //   const tempArray = [];
  //   // endUsers.users.map((item) => {
  //   //   const checked = item.models3D.includes(id);
  //   //   if (checked) {
  //   //     tempArray.push(item.id);
  //   //   }
  //   // });
  //   setAlreadyChecked(tempArray);
  // }, [endUsers.users]);

  const [fileErrors, setFileErrors] = useState({
    arModel: "",
    coverImage: "",
  });

  useEffect(() => {
    if (filesModel.length) {
      formData.set("arFile", filesModel[0]);
    }
  }, [filesModel]);
  useEffect(() => {
    if (filesCoverImageZon.length) {
      setFinalResponse((prevState) => ({
        ...prevState,
        coverImage: filesCoverImageZon,
      }));
      filesCoverImageZon.forEach((image) => {
        formData.append("coverImage", image);
        coverFormData.append("coverImage", image);
      });
    }
  }, [filesCoverImageZon]);
  const callback = () => {
    // navigate("/models");
    setOpenState(true);
  };
  const submit = (e) => {
    e.preventDefault();
    if (!filesModel || filesModel.length <= 0) {
      setFileErrors({
        ...fileErrors,
        arModel: "A model is required",
        coverImage: "",
      });
    }

    dispatch(uploadFinalModel(id, formData, callback));
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ".glb,.gltf,.glTF,.GLB",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFilesModel(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const convertToFormData = (values) => {
    Object.keys(values).map((val) => {
      if (val !== "coverImage" || val !== "arFile")
        formData.set(val, values[val]);
    });
  };
  const coverImageZone = useDropzone({
    accept: "image/*",
    multiple: true,
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      setFilesCoverImageZone(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const fileRejectionItemsModel = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul className="w-full text-center">
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be AR ready 3D model"
              : e.message}
            <br />
            {file.path}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileRejectionItemsCover = coverImageZone.fileRejections.map(
    ({ file, errors }) => (
      <div key={file.path}>
        <ul className="w-full text-center">
          {errors.map((e) => (
            <li className="text-red-500 font-bold" key={e.code}>
              {e.code === "file-invalid-type"
                ? "The file must be image"
                : e.message}
            </li>
          ))}
        </ul>
      </div>
    )
  );
  const fileAccpetedItemsModel = filesModel.map((file) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });
  const fileAccpetedItemsCover = finalResponse.coverImage.map((file) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });

  const handleUpload = (data) => {
    const conditions =
      data.name !== singleModel.name ||
      data.description !== singleModel.description ||
      data.rooms !== singleModel.rooms ||
      data.bathrooms !== singleModel.bathrooms ||
      data.materials.length !== singleModel.materials.length ||
      data.materials.join() !== singleModel.materials.join() ||
      data.area !== singleModel.area ||
      data.numberOfFloors !== singleModel.numberOfFloors ||
      data.floorHeight !== singleModel.floorHeight;
    convertToFormData(data);
    data.materials = data.materials.join();
    if (finalResponse.coverImage && finalResponse.coverImage.length > 0) {
      dispatch(
        coverImageUpdate(singleModel.id, coverFormData, !conditions && callback)
      );
    }

    // if (alreadyChecked.length > 0) {
    //   dispatch(
    //     assignModel(
    //       {
    //         currentUser: singleModel.userId,
    //         modelId: singleModel.id,
    //         endUserIds: alreadyChecked,
    //       },
    //       callbackAssignment
    //     )
    //   );
    // }
    // if (unchecked.length > 0) {
    //   dispatch(
    //     removeAssignment(
    //       {
    //         currentUser: singleModel.userId,
    //         modelId: singleModel.id,
    //         endUserIds: unchecked,
    //       },
    //       callbackAssignment
    //     )
    //   );
    // }
    if (conditions) dispatch(updateModel(singleModel.id, data, callback));
  };
  const [defaultParrent, setDefaultParrent] = useState({
    name: "",
    id: "",
  });
  const [defaultCategory, setDefaultCategory] = useState({
    name: "",
    id: "",
  });
  useEffect(() => {
    reset(singleModel);
    if (singleModel.category?.parrent_id) {
      const findCategory = parrentCategories.categories?.find(
        (cat) => cat.id === singleModel.category.parrent_id
      );
      const findSecondCategory = categories.categories?.find(
        (cat) => cat.id === singleModel.category.id
      );
      if (findCategory) {
        setDefaultParrent({ name: findCategory.name, id: findCategory.id });
        setParrentCategoryValues(findCategory.id);
      }

      if (findSecondCategory) {
        setDefaultCategory({
          name: findSecondCategory.name,
          id: findSecondCategory.id,
        });
      }
      // setOptionsParrent(parrentCategories.categories);
    }
    // dispatch(getChildCategories(singleModel.category.parrent_id));
  }, [singleModel]);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // const callbackAssignment = () => {
  //   dispatch(updateUserData());
  //   // dispatch(fetchEndUsers(0, endUsers.total));
  //   // closeModal(false);
  // };
  const removeModel = () => {
    dispatch(deleteModel(singleModel.id));
    dispatch(updateUserData());
    dispatch(fetchModels({ search: "" }));
    navigate("/models");
  };
  // const handleClickAssignments = (userId) => {
  //   const index = alreadyChecked.indexOf(userId);
  //   if (index > -1) {
  //     setAlreadyChecked((prev) => prev.filter((e) => e !== userId));
  //     setUchecked([...unchecked, userId]);
  //   } else {
  //     setAlreadyChecked([...alreadyChecked, userId]);
  //     setUchecked((prev) => prev.filter((e) => e !== userId));
  //   }
  // };
  const backendErrors = () => {
    if (error && error.length > 0) {
      return error.map((e) => (
        <li className="text-xs text-red-500 " key={e.code}>
          {e.message}
          <br />
        </li>
      ));
    }
  };
  // const searchEndUser = (input) => {
  //   setSearchValue(input);

  //   if (input === "" || input === undefined) {
  //     dispatch(fetchEndUsers(0, endUsers.total));
  //   } else {
  //     dispatch(searchEndUsers(input, 0, endUsers.total));
  //   }
  // };
  const handleChangeParrentCategory = (val) => (e) => {
    if (val) {
      setParrentCategoryValues(val.id);
    } else {
      setParrentCategoryValues(e.target.value);
    }
  };
  // const searchAUsers = (input) => {
  //   setUserSearchValue(input);
  //   if (input === "" || input === undefined) {
  //     dispatch(fetchUsers(0, totalUsers));
  //   } else {
  //     dispatch(searchUsers(input, 0));
  //   }
  // };
  useEffect(() => {
    if (parrentCategoryValues)
      dispatch(getChildCategories(parrentCategoryValues));
  }, [parrentCategoryValues]);
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/models"}
        iconColor={singleModel.arReady && "bg-green"}
        subtitle={singleModel.arReady && "The model is AR ready"}
        title={singleModel.name}
        Icon={ViewInArIcon}
        action={() => dispatch(downloadSingleModel(id, singleModel.name))}
        // link={`${process.env.REACT_APP_API}/admin/model/${id}`}
        buttonTitle="Download Model"
      />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form
          onSubmit={(e) => submit(e)}
          className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap"
        >
          <div className="w-full">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              AR Ready file
            </h3>
            <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
              <div
                className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2 p-12"
                {...getRootProps()}
              >
                <div className="uploadModelForm-image">
                  <ImageIcon fontSize="large" color="disabled" />
                </div>
                <p className="text-center px-2 py-1">
                  Drag & Drop files here or Browse Files
                </p>

                <input {...getInputProps()} />
                {fileRejectionItemsModel}
                {fileAccpetedItemsModel}
                <div className="uploadModelForm-subTitle">
                  Max size: 50MB | File type: GLB, GLTF
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {fileErrors.model && fileErrors.model}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <MainButton
              title="Upload final model"
              type="submit"
              width="w-full sm:w-3/6"
            />
          </div>
        </form>

        <div className="py-2 align-middle inline-block min-w-full w-full my-4">
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Model name
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="name"
                    type="text"
                    defaultValue={singleModel.name}
                    placeholder={"Model name"}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Other detail
                </h3>
                <span className="text-xs text-blue-400 invisible">
                  Not mandatory field
                </span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="otherDetail"
                    type="text"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.otherDetail}
                    placeholder={"Other detail"}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.otherDetail && errors.otherDetail.message}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Parrent Category
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <Controller
                  control={control}
                  render={({
                    field,
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <div
                        className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                      >
                        <Autocomplete
                          disablePortal
                          className="w-full"
                          id="combo-box-demo"
                          value={defaultParrent}
                          onChange={(e, val) => {
                            if (val) {
                              field.onChange(val.id);
                              setDefaultParrent(val);
                            } else {
                              field.onChange(undefined);
                            }
                            handleChangeParrentCategory(val)(e);
                          }}
                          options={
                            parrentCategories.categories
                              ? parrentCategories.categories
                              : []
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": {
                                  mr: 2,
                                  flexShrink: 0,
                                },
                              }}
                              {...props}
                            >
                              <div className="flex items-center justify-start flex-row">
                                <p> {option.name} </p>
                              </div>
                            </Box>
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select a parrent category"
                              disableUnderline={true}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              // onChange={(e) => {
                              //   onChange(e.target.value);
                              //   handleChangeParrentCategory(e);
                              // }}
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                  defaultValue={defaultParrent}
                  name="parrentCategory"
                  rules={{
                    required: {
                      value: true,
                      message: "Category is required",
                    },
                  }}
                />

                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.parrentCategory && errors.parrentCategory.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Category
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <div
                        className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                      >
                        <Autocomplete
                          disablePortal
                          className="w-full"
                          id="combo-box-demo"
                          defaultValue={singleModel.category}
                          value={defaultCategory}
                          onChange={(e, val) => {
                            if (val) {
                              onChange(val.id);
                              setDefaultCategory(val);
                            } else {
                              onChange(undefined);
                            }
                            // handleChangeParrentCategory(val)(e);
                          }}
                          // options={
                          //   parrentCategoryValues ? categories.categories : []
                          // }
                          options={
                            categories.categories ? categories.categories : []
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          noOptionsText={
                            parrentCategoryValues
                              ? "No options"
                              : "Select a parrent category first"
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": {
                                  mr: 2,
                                  flexShrink: 0,
                                },
                              }}
                              {...props}
                            >
                              <div className="flex items-center justify-start flex-row">
                                <p> {option.name} </p>
                              </div>
                            </Box>
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select a category"
                              disableUnderline={true}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                  defaultValue={singleModel.category}
                  name="category"
                  rules={{
                    required: {
                      value: true,
                      message: "Category is required",
                    },
                  }}
                />

                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.category && errors.category.message}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Number of floors
                </h3>

                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="numberOfFloors"
                    type="number"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.numberOfFloors || 1}
                    min={1}
                    placeholder={"Number of Floors"}
                    rules={{
                      pattern: {
                        value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                        message: "Please use numbers only",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.numberOfFloors && errors.numberOfFloors.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Floor height (m)
                </h3>

                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="floorHeight"
                    type="number"
                    min={1}
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.floorHeight}
                    placeholder={"Floor height"}
                    rules={{
                      pattern: {
                        value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                        message: "Please use numbers only",
                      },
                    }}
                  />
                </div>

                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.floorHeight && errors.floorHeight.message}
                </p>
              </div>
            </div>

            <div className="w-full px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Client description
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <Controller
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <TextareaAutosize
                      className="textArea"
                      aria-label="minimum height"
                      minRows={3}
                      inputProps={{ "aria-label": "controlled" }}
                      placeholder="A few words about your client..."
                      onChange={(e) => onChange(e)}
                      defaultValue={singleModel.description}
                    />
                  );
                }}
                // defaultValue={singleModel.description}
                name="description"
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.description && errors.description.message}
              </p>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/4 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Rooms
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="rooms"
                    type="number"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.rooms}
                    placeholder={"ex. 4"}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.rooms && errors.rooms.message}
                </p>
              </div>
              <div className="w-full sm:w-1/4 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Bathrooms
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="bathrooms"
                    type="number"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.bathrooms}
                    placeholder={"ex. 3"}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.bathrooms && errors.bathrooms.message}
                </p>
              </div>
              <div className="w-full sm:w-1/4 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Materials
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <Controller
                  control={control}
                  render={({
                    field,
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <div
                        className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                      >
                        <Autocomplete
                          disablePortal
                          multiple
                          className="w-full"
                          id="material-box"
                          onChange={(e, val) => {
                            let arr = [];
                            val.map((value) => {
                              if (value) {
                                arr.push(value);
                              } else {
                                arr = [];
                              }
                            });

                            field.onChange(arr);
                            // handleChangeParrentCategory(val)(e);
                          }}
                          options={[
                            "Wood",
                            "Concrete",
                            "Stone",
                            "Metal",
                            "Traditional",
                          ]}
                          defaultValue={singleModel.materials}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": {
                                  mr: 2,
                                  flexShrink: 0,
                                },
                              }}
                              {...props}
                            >
                              <p> {option} </p>
                            </Box>
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select materials"
                              disableUnderline={true}
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              // onChange={(e) => {
                              //   onChange(e.target.value);
                              //   handleChangeParrentCategory(e);
                              // }}
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                  name="materials"
                  rules={{
                    required: {
                      value: true,
                      message: "Material is required",
                    },
                  }}
                />

                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.materials && errors.materials.message}
                </p>
              </div>
              <div className="w-full sm:w-1/4 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Area
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="area"
                    type="number"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={singleModel.area}
                    placeholder="ex. 120 sqm"
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.area && errors.area.message}
                </p>
              </div>
            </div>
            <div className="w-full">
              {/* <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Assign model {singleModel.name} to end user
              </h3> */}
              {/* <SearchField
                // action={searchEndUser}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                width="w-full"
              /> */}
              {/* <div className="max-h-60 bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2 p-2">
                <div className="w-full overflow-y-auto p-1"> */}
              {/* {endUsers.users &&
                    endUsers.users.length > 0 &&
                    endUsers.users.map((item, i) => {
                      const checked = alreadyChecked.includes(item.id);

                      return (
                        <div
                          className={`w-full flex items-center my-2 p-2 relative cursor-pointer ${
                            checked && "bg-primary bg-opacity-20"
                          }`}
                          key={i}
                          onClick={() =>
                            handleClickAssignments(item.id, checked)
                          }
                        >
                          <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full">
                            {item.profilePicture ? (
                              <img
                                src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}`}
                                className="object-cover w-full h-full"
                              />
                            ) : (
                              <FaceIcon className="text-white" />
                            )}
                          </div>
                          <div className="w-full flex justify-between items-center text-sm font-medium text-gray-900 ml-4">
                            <div>
                              {" "}
                              {item.name}
                              <div className="text-sm text-gray-500">
                                {item.phone} {item.email}
                              </div>
                            </div>
                            {checked ? (
                              <div className="absolute -right-5">
                                <CheckBoxFull />
                              </div>
                            ) : (
                              <div className="absolute -right-5">
                                <CheckBox />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })} */}
              {/* </div>
              </div> */}
            </div>
            <div className="w-full">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Model Cover
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
                <div
                  className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
                  {...coverImageZone.getRootProps()}
                >
                  <p className="text-center px-2 py-1">
                    Drag & Drop files here or Browse Files
                  </p>
                  <p className="text-center px-2 py-1">File type: JPG, PNG </p>
                  <input {...coverImageZone.getInputProps()} />
                  {fileRejectionItemsCover}
                  {fileAccpetedItemsCover}
                  <div className="flex flex-col sm:flex-row justify-center  items-center">
                    {filesCoverImageZon && filesCoverImageZon.length > 0 ? (
                      filesCoverImageZon.map((image, i) => {
                        return (
                          <div
                            className="w-full sm:w-1/4 h-72 p-2 max-w-2xl"
                            key={i}
                          >
                            <img
                              src={image.preview}
                              alt="..."
                              className="w-full h-full rounded-xl object-cover"
                            />
                          </div>
                        );
                      })
                    ) : singleModel.coverImages &&
                      singleModel.coverImages.length > 0 ? (
                      singleModel.coverImages.map((image, i) => {
                        return (
                          <div className="w-full h-72 p-2 max-w-2xl" key={i}>
                            <img
                              src={`${process.env.REACT_APP_API}${image.usePath}/${image.filename}`}
                              alt="..."
                              className="w-full h-full rounded-xl object-cover"
                            />
                          </div>
                        );
                      })
                    ) : singleModel.coverImagePath ? (
                      <>
                        <div className="w-full h-72 p-12 max-w-2xl">
                          <img
                            src={`${process.env.REACT_APP_API}/models3D/content/image/${singleModel.id}/${singleModel.updatedAt}`}
                            alt={singleModel.name}
                            className="w-full h-full object-cover rounded-xl"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="w-6/12 sm:w-4/12  flex flex-wrap justify-center py-1">
                        <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                          <ImageIcon fontSize="large" className="text-white" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="uploadModelForm-subTitle">
                    Max size: 5MB | File type: JPG, JPEG, PNG{" "}
                  </div>
                  <p
                    className="text-xs text-red-500 "
                    style={{ minHeight: "1rem" }}
                  >
                    {fileErrors.coverImage && fileErrors.coverImage}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Add this model to marketplace
              </h3>

              <div className={` flex justify-center  items-center`}>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <Switch
                        onChange={(e) => onChange(e.target.checked)}
                        defaultChecked={false}
                        checked={value || false}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    );
                  }}
                  name="marketplace"
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.marketplace && errors.marketplace.message}
              </p>
            </div>
            <div
              className={
                "w-full flex justify-center items-center flex-col py-4"
              }
            >
              <MainButton
                action={handleSubmit(handleUpload)}
                title="Save"
                width="w-full sm:w-3/6"
              />

              <p
                className="text-xs text-red-500 w-full text-center py-4 "
                style={{ minHeight: "1rem" }}
              ></p>
            </div>
            <ul>{backendErrors()}</ul>
          </form>
          {/* <div
            className={"w-full flex justify-center items-center flex-col py-4"}
          >
            {" "}
            <SecondaryButton
              Icon={<FileCopyIcon />}
              action={() => setDuplicateModal(true)}
              title="Duplicate model"
              color="text-primary"
              width="w-full sm:w-2/6"
            />
          </div> */}
          <div
            className={"w-full flex justify-center items-center flex-col py-4"}
          >
            {" "}
            <SecondaryButton
              alert={true}
              Icon={<DeleteForeverIcon />}
              action={() => setRemoveModal(true)}
              title="Remove this model"
              width="w-full sm:w-2/6"
            />
          </div>
        </div>
      </div>
      <RemoveModal
        visibility={removeModal}
        setVisibility={setRemoveModal}
        submitAction={removeModel}
        title="Are you sure you want to remove this model?"
        buttonText="Remove model"
        description={`This action is irreversible`}
      />
      <Modal
        title={`Duplicate model ${singleModel.name}`}
        visibility={duplicateModal}
        setVisibility={setDuplicateModal}
        // action={handleSubmit(handleDuplicate)}
      >
        <form>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl `}
              >
                <Input
                  control={control}
                  fieldName="duplicateName"
                  defaultValue={singleModel.name}
                  // type={showPassword ? "text" : "password"}
                  type="text"
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                    required: {
                      value: true,
                      message: "Please add the users name",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.duplicateName && errors.duplicateName.message}
              </p>
            </div>
          </div>
          {/* <SearchField
            // action={searchAUsers}
            searchValue={userSearchValue}
            setSearchValue={setUserSearchValue}
            width="w-full"
          /> */}
          {/* {users &&
            users.users?.length > 0 &&
            users.users.map((item, i) => {
              const checked = duplicationUserId === item.id;

              return (
                <div
                  className={`w-full flex items-center my-2 p-2 relative cursor-pointer ${
                    checked && "bg-primary bg-opacity-20"
                  }`}
                  key={i}
                  onClick={() => {
                    setDuplicationUserId(item.id);
                    // checked = item.id;
                  }}
                >
                  <div className="flex justify-start  items-center w-3/4">
                    <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                      {item.profilePicture ? (
                        <img
                          src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}`}
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <FaceIcon className="text-white" />
                      )}
                    </div>
                    <div className="text-sm font-medium text-gray-900 ml-4">
                      {item.name}
                      <div className="text-sm text-gray-500">
                        {item.phone} {item.email}
                      </div>
                    </div>
                  </div>
                  {checked ? (
                    <div className="absolute right-0">
                      <CheckBoxFull />
                    </div>
                  ) : (
                    <div className="absolute right-0">
                      <CheckBox />
                    </div>
                  )}
                </div>
              );
            })} */}
          <ul className="w-full flex justify-center items-center">
            {backendErrors()}
          </ul>
          {/* <div className="w-full p-2">
            <MainButton
              action={}
              title="Duplicate"
            />
          </div> */}
        </form>
      </Modal>
    </div>
  );
};

export default PartnerSingleModel;
