import React from "react";
import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const SuccessModal = ({ setVisibility, visibility, title, description }) => {
  const hideModal = () => {
    setVisibility(false);
  };
  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="w-full text-center flex flex-col justify-center items-center">
          <CheckCircleOutlineOutlinedIcon
            color={"success"}
            sx={{ fontSize: 131 }}
          />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 p-2">
            <MainButton
              action={() => {
                hideModal();
              }}
              title={"Done"}
              width="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
