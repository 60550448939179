import React from "react";
import { Link } from "react-router-dom";

class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        {/* <img src={PageNotFound} /> */}
        Error
        <p style={{ textAlign: "center" }}>
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}
export default ErrorPage;
