import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Version from "../../ui/primitives/Version";
import DashboardsNavbar from "../../ui/nav/DashboardsNavbar";

import Footer from "../../ui/nav/Footer";

const EndUserLayout = () => {
  const { user } = useSelector((state) => state.userData);
  return (
    <div className="p-2">
      <DashboardsNavbar
        showSidebar={true}
        setShowSidebar={() => {}}
        isMarketPlace={true}
        marketUser={user}
      />
      <div className="flex flex-col w-auto">
        <Outlet />
      </div>
      <Version />
      <Footer />
    </div>
  );
};

export default EndUserLayout;
