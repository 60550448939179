import React, { useState, useEffect } from "react";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MainButton from "../buttons/MainButton";

import { useDispatch, useSelector } from "react-redux";
import {
  singUpEndUser,
  resetSignUpData,
} from "../../../redux/modules/user/actions";
import { Controller, useForm } from "react-hook-form";
const GettingStartedForm = ({ title, subtitle, callback, signupCallback }) => {
  const [showErrors, setShowErrors] = useState(false);

  const { endUserSaved, endUserSignUpError } = useSelector(
    (state) => state.userData
  );

  React.useEffect(() => {
    if (endUserSaved) {
      dispatch(resetSignUpData());
      if (callback) callback();
    }
  }, [endUserSaved]);

  const dispatch = useDispatch();

  const submit = (data) => {
    dispatch(singUpEndUser(data.email, data.name, data.phone));
    if (signupCallback) {
      signupCallback();
    }
  };
  useEffect(() => {
    if (endUserSignUpError && endUserSignUpError.length > 0) {
      setShowErrors(true);
    }
  }, [endUserSignUpError]);

  const errorDisplay = () => {
    if (endUserSignUpError.length > 0) {
      return endUserSignUpError.map((error, i) => {
        return (
          <p key={i} className="text-xs text-red-500 ">
            {error.message}
          </p>
        );
      });
    }
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  return (
    <form className="w-full h-full flex justify-center  items-center flex-col">
      <div className="z-10 w-full p-5 flex justify-center items-center flex-row">
        <div className="">
          <h2 className="font-bold text-center justify-center items-center text-black py-2.5">
            {title || "Getting Started"}
          </h2>
        </div>
      </div>
      <p className="font-medium text-center text-fade leading-7 tracking-wide">
        {subtitle || "Sign up to XRETEH and continue"}
      </p>
      <div className="w-full flex justify-center items-center flex-col">
        <div className="w-full sm:w-5/6 flex justify-center items-center py-2 px-7  border border-fade rounded-4xl my-4">
          <MailOutlineIcon />
          <Controller
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <input
                  onChange={(e) => onChange(e)}
                  placeholder="Email"
                  className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                />
              );
            }}
            name="email"
            rules={{
              pattern: {
                value:
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                message: "Please use a valid email address",
              },
              required: {
                value: true,
                message: "Please add an email address",
              },
            }}
          />
        </div>
        <p className="text-xs text-red-500 ">
          {errors.email && errors.email.message}
        </p>
        <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
          <BadgeTwoToneIcon />
          <Controller
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <input
                  onChange={(e) => onChange(e)}
                  placeholder="Name"
                  className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                />
              );
            }}
            name="name"
            rules={{
              minLength: {
                value: 4,
                message: "The min length of the name is 4",
              },
              required: {
                value: true,
                message: "Your name is required",
              },
            }}
          />
        </div>
        <p className="text-xs text-red-500 ">
          {errors.name && errors.name.message}
        </p>
        <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
          <LocalPhoneIcon />
          <Controller
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => {
              return (
                <input
                  onChange={(e) => onChange(e)}
                  placeholder="Phone"
                  className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
                />
              );
            }}
            name="phone"
            rules={{
              pattern: {
                value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                message: "Please use a valid phone number",
              },
              required: {
                value: true,
                message: "Please add a phone number",
              },
            }}
          />
        </div>
        <p className="text-xs text-red-500 ">
          {errors.phone && errors.phone.message}
        </p>
      </div>
      <div className="flex justify-center items-center py-2 px-7"></div>
      {/* <FormGroup className="flex justify-center items-center">
        <FormControlLabel
          control={<Checkbox />}
          label={
            <div className="text-xs">
              By signing up it means you agree to the{" "}
              <b>Terms and Conditions</b>, and our <b>Privacy Policy</b>
            </div>
          }
        />
      </FormGroup> */}
      {showErrors && endUserSignUpError.length > 0 && errorDisplay()}
      <div className="flex justify-center items-center flex-col w-full my-4">
        <MainButton
          title="Enter the virtual world"
          type="submit"
          width="w-full mt-4 sm:w-5/6"
          action={handleSubmit(submit)}
        />
      </div>
    </form>
  );
};

export default GettingStartedForm;
