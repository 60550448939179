import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";
import LogoutIcon from "@mui/icons-material/Logout";
import { signoutEndUser } from "../../redux/modules/auth/actions";
import {
  profilePictureUploadEndUser,
  updateEndUserData,
  profileDatilsEndUserUpdate,
  clearError,
} from "../../redux/modules/user/actions";
import clearAllStoreData from "../../redux/resetAction";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import InternalTopNavbar from "../../Components/ui/nav/InternalTopNavbar";
let modalOpen = false;
const MyAccoountEndUserPage = () => {
  const [profile, setPicture] = React.useState([]);
  const { userError, user } = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const [rerender, setRerender] = useState(false);
  const dispatch = useDispatch();
  const [initData, setInitData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  useEffect(() => {
    if (profile.length) {
      formData.set("profilePicture", profile[0]);
    }

    profile.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [profile]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPicture(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  useEffect(() => {
    dispatch(clearError());
    setInitData({
      name: user.name,
      phone: user.phone,
      email: user.email,
    });
    setValue("name", user.name);
    setValue("phone", user.phone);
    setValue("email", user.email);
  }, [user]);
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [formData] = useState(new FormData());
  const backendErrors = () => {
    return userError.map((e) => (
      <li className="text-xs text-red-500" key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }
  const callback = () => {
    dispatch(updateEndUserData());
    setModalOpen(true);
  };
  const signoutCallback = () => {
    dispatch(clearAllStoreData());
    navigate("/signin");
  };
  const submitSwitch = (data) => {
    if (!shallowEqual(initData, data)) {
      dispatch(
        profileDatilsEndUserUpdate(
          {
            name: data.name,
            phone: data.phone,
            email: data.email,
          },
          callback
        )
      );
    }
    if (profile && profile.length > 0) {
      dispatch(profilePictureUploadEndUser(formData, callback));
    }
  };
  const setModalOpen = (value) => {
    modalOpen = value;
    setRerender(!rerender);
  };
  return (
    <div className="w-full flex justify-center  items-center flex-col">
      {/* <TobNavBar /> */}
      <SuccessModal
        visibility={modalOpen}
        setVisibility={setModalOpen}
        title={"The update operation succeeded"}
      />
      <InternalTopNavbar title="My profile" />
      <form
        className="flex justify-center flex-col items-center w-full lg:w-1/2 shadowBox p-8"
        style={{ margin: "20px auto 20px auto" }}
      >
        <div
          className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
          {...getRootProps()}
        >
          <p className="text-center px-2 py-1">
            Drag & Drop files here or Browse Files
          </p>
          <p className="text-center px-2 py-1">File type: JPG, PNG </p>
          <input {...getInputProps()} />

          <div className="w-6/12 sm:w-4/12  flex flex-wrap justify-center py-1">
            <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
              {profile && profile.length > 0 ? (
                <img
                  src={profile[0].preview}
                  alt="..."
                  className="w-full h-28 object-cover"
                />
              ) : user.profilePicture ? (
                <img
                  src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                  alt="..."
                  className="w-full h-28 object-cover"
                />
              ) : (
                <ImageIcon fontSize="large" className="text-white" />
              )}
            </div>
          </div>
        </div>
        <div className="w-3/4 flex flex-col">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Phone
          </h3>
          <div
            className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
          >
            <Input
              control={control}
              fieldName="phone"
              defaultValue={user.phone}
              placeholder={"+CC XXX XXX XXX"}
              type="text"
              disabled={true}
              rules={{
                pattern: {
                  value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                  message: "Please use a valid phone number",
                },
              }}
            />
          </div>
          <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
            {errors.phone && errors.phone.message}
          </p>
        </div>
        <div className="w-3/4 flex flex-col">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Name
          </h3>
          <div
            className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
          >
            <Input
              control={control}
              fieldName="name"
              type="text"
              // type={showPassword ? "text" : "password"}
              defaultValue={user.name}
              placeholder={"Name"}
              rules={{
                minLength: {
                  value: 4,
                  message: "The min length of the name is 4",
                },
              }}
            />
          </div>
          <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
            {errors.name && errors.name.message}
          </p>
        </div>

        <div className="w-3/4 flex flex-col">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Email
          </h3>
          <div
            className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
          >
            <Input
              control={control}
              fieldName="email"
              type="email"
              // type={showPassword ? "text" : "password"}
              defaultValue={user.email}
              placeholder={"Email"}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  message: "Please use a valid email address",
                },
                required: {
                  value: true,
                  message: "Please add the users email",
                },
              }}
            />
          </div>
          <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
            {errors.email && errors.email.message}
          </p>
        </div>
        <div className="w-full p-2 flex justify-center  items-center">
          <MainButton
            action={handleSubmit(submitSwitch)}
            title="Save"
            width="w-2/6"
          />
        </div>
        <ul className="w-full flex justify-center items-center">
          {backendErrors()}
        </ul>
      </form>
      <div className="rounded-lg mb-2 text-gray-700">
        <button
          className="flex items-center text-sm text-red-500 font-light px-4 py-2 rounded-lg"
          onClick={() => {
            dispatch(signoutEndUser(signoutCallback));
          }}
        >
          <LogoutIcon />
          <span className="ml-2">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default MyAccoountEndUserPage;
