import React, { useEffect, useState } from "react";

import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import BathtubIcon from "@mui/icons-material/Bathtub";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ViewInAr from "@mui/icons-material/ViewInAr";
import arrow from "../../staticfiles/arrow.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import applestore from "../../staticfiles/applestore.png";
import playStore from "../../staticfiles/playStore.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useDispatch, useSelector } from "react-redux";
import {
  // updateEndUserData,
  addModelToFavorite,
  removeModelToFavorite,
} from "../../redux/modules/user/actions";
import {
  fetchSingleModelMarketPlace,

  // assignModel,
  // removeAssignment,
  // updateModel,
  // coverImageUpdate,
  // getParrentCategories,
  // getChildCategories,
} from "../../redux/modules/models/actions";
import { Link, useParams } from "react-router-dom";
import MarketPlaceLayout from "../../Components/layouts/MarketplaceLayout";
import GettingStartedForm from "../../Components/ui/forms/GettingStartedForm";
import EndUserSigninForm from "../../Components/ui/forms/EndUserSigninForm";
import EditIcon from "@mui/icons-material/Edit";
import SecondaryButton from "../../Components/ui/buttons/SecondaryButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const SingleMarketModel = (props) => {
  const { modelId } = useParams();
  const dispatch = useDispatch();
  console.log(location.state);
  const [isFavorite, setIsFavorite] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.userData);
  const { singleModel } = useSelector((state) => state.modelData);
  useEffect(() => {
    // window.scrollTo(0, 0);

    dispatch(fetchSingleModelMarketPlace(modelId));
  }, []);
  const [signUpForm, setSignUpForm] = useState(true);
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/marketplace");
  //   }
  //   // dispatch(clearError());
  // }, [isAuthenticated]);

  // const callback = () => {
  //   dispatch(updateEndUserData());
  // };
  const addToFavorite = () => {
    if (!isFavorite) {
      dispatch(addModelToFavorite(singleModel.id));
    } else {
      dispatch(removeModelToFavorite(singleModel.id));
    }
  };

  const checkIsFavorite = () => {
    if (user.favorites && user.favorites.length > 0) {
      const favorite = user.favorites.find((id) => id === singleModel.id);
      if (favorite) {
        setIsFavorite(singleModel.id);
      } else {
        setIsFavorite(null);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkIsFavorite();
    }
  }, [singleModel.id, user.favorites]);

  return (
    <MarketPlaceLayout
      // user={user}
      sidebar={false}
      // setFilters={setFilters}
      // filters={filters}
      // categories={categories}
      // clearFilters={clearFilters}
    >
      <div style={{ overflow: "auto" }} className="py-4">
        <div className="flex justify-center items-center	flex-col p-2">
          <div className="w-4/5 flex justify-start">
            <SecondaryButton
              title="Back"
              width={"w-full"}
              color="text-primary"
              Icon={<ArrowBackIcon />}
              link="/marketplace"
            />
          </div>
          <div className="shadowBox mt-4 w-full lg:w-4/5 ">
            <div className="w-full px-4 py-2 flex justify-between items-center">
              {!user.role && isAuthenticated && singleModel.arReady ? (
                isFavorite === singleModel.id ? (
                  <div
                    onClick={addToFavorite}
                    className="bg-white w-64 shadow hover:shadow-lg flex justify-center items-center rounded-full cursor-pointer p-2"
                  >
                    <FavoriteIcon color={"error"} className="mr-4" />
                    Remove from favorites
                  </div>
                ) : (
                  <div
                    className="bg-white w-64 shadow hover:shadow-lg max-w-xs flex justify-center items-center rounded-full cursor-pointer p-2"
                    onClick={addToFavorite}
                  >
                    {" "}
                    <FavoriteBorderIcon color={"error"} className="mr-4" />
                    Add to favorites
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="w-full flex justify-end px-4 py-2">
              {(isAuthenticated && user.role === "Admin") ||
              (isAuthenticated &&
                user.role === "userA" &&
                user.id === singleModel.userId?.id) ? (
                <Link
                  to={
                    user.role === "Admin"
                      ? `/models/${singleModel.id}`
                      : `/model/${singleModel.id}`
                  }
                  className="bg-white w-64 shadow hover:shadow-lg flex justify-center items-center rounded-full cursor-pointer p-2 text-primary"
                >
                  <EditIcon className="mr-4" />
                  Edit model
                </Link>
              ) : (
                <></>
              )}
            </div>

            <h3 className="mt-2 ml-10">{singleModel.name}</h3>
            <div className="p-10">
              <div className="flex flex-wrap justify-center items-center">
                {singleModel.coverImages &&
                singleModel.coverImages.length > 0 ? (
                  singleModel.coverImages.map((image, index) => {
                    return (
                      <div
                        className="rounded-xl overflow-hidden w-271 h-172 m-2 "
                        key={index}
                      >
                        <img
                          src={`${process.env.REACT_APP_API}${image.usePath}/${image.filename}`}
                          className={"object-cover object-center w-271 h-172"}
                        />
                      </div>
                    );
                  })
                ) : singleModel.coverImagePath ? (
                  <>
                    <div className="w-full h-72 p-12 max-w-2xl">
                      <img
                        src={`${process.env.REACT_APP_API}/models3D/content/image/${singleModel.id}/${singleModel.updatedAt}`}
                        alt={singleModel.name}
                        className="w-full h-full object-cover rounded-xl"
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#F4F4F4" }}
            className="rounded-full max-w-7xl p-4 mt-4 flex justify-center items-center w-full  lg:w-4/5"
          >
            <div className="mx-1 lg:mx-3 shadowBox p-2 flex flex-col lg:flex-row justify-center items-center text-center text-xs lg:text-base">
              <BedroomParentIcon />
              <p>
                {singleModel.rooms} {" rooms"}
              </p>
            </div>
            <div className="mx-1 lg:mx-3 shadowBox p-2 flex flex-col lg:flex-row justify-center items-center text-center text-xs lg:text-base">
              <BathtubIcon />
              {singleModel.bathrooms} {" bathrooms"}
            </div>
            <div className="mx-1 lg:mx-3 shadowBox p-2 flex flex-col lg:flex-row justify-center items-center text-center text-xs lg:text-base">
              <SquareFootIcon />
              {singleModel.area} {" sqm"}
            </div>
            {singleModel.arReady ? (
              <div className="mx-1 lg:mx-3 shadowBox p-2 flex flex-col lg:flex-row justify-center items-center text-center text-xs lg:text-base">
                <ViewInAr color="success" />
                AR Ready
              </div>
            ) : (
              <div className="ml-4 shadowBox px-2 py-1">
                <ViewInAr className="text-gray-500 " />
                Not ready for AR
              </div>
            )}
          </div>

          <div
            className={
              "flex justify-content items-center w-full lg:w-4/5 flex-col lg:flex-row p-2"
            }
          >
            <div className=" shadowBox lg:mt-4 lg:mr-4 px-12 h-full py-8 w-full lg:w-8/12">
              <h2 className="p-2">Model description</h2>
              {singleModel.description}
              <h3 className="p-2">Other details</h3>
              {singleModel.otherDetails}
            </div>

            <div className="flex p-8 justify-center items-center flex-col mt-4 h-full w-full lg:w-4/12 shadowBox">
              {" "}
              <div
                className="my-4 text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "26px",
                  lineHeight: "32px",
                }}
              >
                Download our app
              </div>
              <div className="flex justify-center items-center flex-row">
                <div className="w-1/2 p-1">
                  <img src={playStore} className="object-cover w-full" />
                </div>
                <div className="w-1/2 p-1">
                  <img src={applestore} className="object-cover w-full" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-between items-center relative w-full lg:w-4/5 flex-col lg:flex-row p-2">
            <div className="shadowBox  my-4 lg:mx-4 py-16 px-10  w-full lg:w-7/12">
              <h3>How it works?</h3>
              <div>
                The perfect AR design tool for architects! We offer easy-to-use
                tools to create stunning AR designs that can be easily shared
                with your clients.
              </div>
              <br />
              <div>
                <h4 className="font-bold">Create New Experiences</h4> Create a
                more immersive experience for your users and make sure that your
                projects are of the best quality.
              </div>
              <br />
              <div>
                <h4 className="font-bold">Explore</h4> Build anything in a
                fraction of the time with fewer resources. A more efficient way
                to design a project of all sizes.
              </div>
              <br />
              <div>
                <h4 className="font-bold">Discover New Opportunities</h4> With
                our easy to use platform, businesses of all sizes can discover
                new opportunities.
              </div>
            </div>

            {!props.isAuthenticated && (
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  backgroundColor: "white",
                }}
                className=" w-1/12 rounded-full shadowBoxFull flex justify-center items-center transform rotate-90 lg:rotate-0  lg:mt-0"
              >
                <img src={arrow} />
              </div>
            )}

            {isAuthenticated ? (
              <div className="shadowBox h-full flex justify-center items-center flex-col py-16 px-10 w-full lg:w-4/12 my-4">
                <h2> Architect details </h2>

                <div className="w-24 h-24 rounded-full overflow-hidden m-4">
                  <img
                    src={`${process.env.REACT_APP_API}${singleModel.userId?.profilePicture?.usePath}/${singleModel.userId?.profilePicture?.filename}`}
                    alt={singleModel.userId?.company}
                  />
                </div>
                <h4 className="w-full font-bold text-xl text-center">
                  {singleModel.userId?.company}
                </h4>
                <div className="w-full flex items-start justify-between m-4 flex-col">
                  {singleModel.userId?.email && (
                    <div className="w-full flex justify-start items-center pt-4">
                      <MailOutlineIcon
                        className="text-primary mr-6"
                        fontSize="large"
                      />
                      <a href={`mailto:${singleModel.userId?.email}`}>
                        {singleModel.userId?.email}
                      </a>
                    </div>
                  )}
                  {singleModel.userId?.phone && (
                    <div className="w-full flex justify-start items-center pt-4">
                      <LocalPhoneIcon
                        className="text-primary mr-6"
                        fontSize="large"
                      />
                      <a href={`tel:${singleModel.userId?.phone}`}>
                        {singleModel.userId?.phone}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : signUpForm ? (
              <div className="shadowBox px-2 h-full w-full lg:w-4/12 my-4">
                <GettingStartedForm callback={() => setSignUpForm(false)} />
              </div>
            ) : (
              <div className="shadowBox px-2 h-full w-full lg:w-4/12 my-4">
                <EndUserSigninForm />
              </div>
            )}
          </div>
        </div>
      </div>
    </MarketPlaceLayout>
  );
};

export default SingleMarketModel;
