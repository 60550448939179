import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { useDebounce } from "../../hooks/useDebounce";

const SearchField = ({
  action,
  filterRequest,
  showFilterButton,
  setFilters,
  filters,
  searchValue,
  setSearchValue,
  width,
  sortBy,
  sortValue,
}) => {
  const [showFilters, setShowFilters] = React.useState(false);

  useDebounce(
    () => action(searchValue, filters, sortBy, sortValue),
    [searchValue, filters, sortBy, sortValue],
    1000
  );
  const handleChange = (name, val) => (evt) => {
    if (name === "search") {
      setSearchValue(evt.target.value);
    } else if (val) {
      setFilters({ ...filters, [name]: val.value });
    } else {
      setFilters({ ...filters, [name]: evt.target.value });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    action(searchValue, sortBy, sortValue, filters);
  };
  return (
    <form onSubmit={(e) => submit(e)}>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between	 items-center space-between p-4">
          <div
            className={`border-2 rounded-full w-full ${
              width || "sm:w-2/6"
            } p-2 mb-4 flex`}
          >
            <SearchIcon />

            <input
              placeholder="Type here to search..."
              type={"text"}
              value={searchValue}
              onChange={handleChange("search")}
              className={"focus:outline-none w-full ml-2"}
            />
          </div>
          {showFilterButton && (
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="border-2 rounded-full p-2 mb-4 flex cursor-pointer"
            >
              <FilterListTwoToneIcon />
              Filters
            </div>
          )}
        </div>

        {showFilters && (
          <div
            className="shadowBox flex flex-wrap  flex-row justify-betwee items-center space-between p-4 w-full"
            onSubmit={(e) => submit(e)}
          >
            <div
              className="m-2 w-full flex  flex-col"
              style={{ maxWidth: "250px" }}
            >
              {" "}
              <p className="m-2">Filter by room number</p>
              <div className="border-2 rounded-full w-full min-w-min p-2 mb-4 flex ">
                <input
                  placeholder="Type here to search..."
                  type={"text"}
                  onChange={handleChange("rooms")}
                  className={"focus:outline-none w-full ml-2"}
                />
              </div>{" "}
            </div>
            <div
              className="m-2 w-full flex flex-col"
              style={{ maxWidth: "250px" }}
            >
              {" "}
              <p className="m-2 min-w-min">Fliter by bathroom number</p>
              <div className="border-2 rounded-full min-w-min w-full mr-4 p-2 mb-4 flex ">
                <input
                  placeholder="ex. 3"
                  type={"text"}
                  onChange={handleChange("bathrooms")}
                  className={"focus:outline-none w-full ml-2"}
                />
              </div>{" "}
            </div>{" "}
            <div
              className="m-2 w-full flex flex-col"
              style={{ maxWidth: "250px" }}
            >
              {" "}
              <p className="m-2 min-w-min">Fliter by material</p>
              <div className="border-2 rounded-full w-full p-2 mb-4 flex ">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e, val) => handleChange("materials", val)(e)}
                  options={[
                    { label: "Wood", value: "wood" },
                    { label: "Concrete", value: "concrete" },
                    { label: "Stone", value: "stone" },
                    { label: "Metal", value: "metal" },
                    { label: "Traditional", value: "traditional" },
                  ]}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      onClick={() => handleChange("materials", option.label)}
                      component="li"
                      sx={{
                        "& > img": {
                          mr: 2,
                          flexShrink: 0,
                        },
                      }}
                      {...props}
                    >
                      <div className="flex items-center justify-start flex-row">
                        <div className="bg-green-800 w-10 h-10 rounded-full m-1"></div>
                        <p> {option.label} </p>
                      </div>
                    </Box>
                  )}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disableUnderline={true}
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      onChange={handleChange("materials")}
                    />
                  )}
                />
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchField;
