import React from "react";
import { Link } from "react-router-dom";
const InternalTopNavbar = ({ title }) => {
  return (
    <div
      style={{
        backgroundColor: " #0095DA1A",
        width: "80%",
        margin: "0 auto",
        borderBottomLeftRadius: "100px",
        borderBottomRightRadius: "100px",
      }}
      className="flex justify-center items-center flex-col p-4"
    >
      <div id="my-account-label">
        <p
          className="text-center"
          style={{
            color: "#0095DA",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "45px",
            lineHeight: "32px",
            letterSpacing: "0.5px",
          }}
        >
          {title}
        </p>
        <div className="w-full flex justify-between items-center flex-col md:flex-row">
          <Link to="/my-profile" className={` mt-4 mx-4`}>
            <span
              className={`${title !== "My profile" && "text-primary"} ${
                title === "My profile" && "font-bold"
              }`}
            >
              {" "}
              My profile
            </span>
          </Link>
          <Link to="/my-favorites" className={`mt-4 mx-4`}>
            <span
              className={`${title !== "My favorites" && "text-primary"} ${
                title === "My favorites" && "font-bold"
              }`}
            >
              My favorites
            </span>
          </Link>
          <Link to="/my-assigned-models" className={`mt-4 mx-4`}>
            <span
              className={`${title !== "My models" && "text-primary"} ${
                title === "My models" && "font-bold"
              }`}
            >
              My models
            </span>
          </Link>
        </div>
        <div className="mt-4 flex justify-center items-center flex-row">
          <Link to="/marketplace" className="text-primary mt-4">
            Back to marketplace
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InternalTopNavbar;
