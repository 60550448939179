import React, { useState, useEffect } from "react";
import {
  assignModel,
  removeAssignment,
  errorClear,
} from "../../../redux/modules/models/actions";
import { updateUserData } from "../../../redux/modules/user/actions";
import // fetchModels as fetchModelsAdmin,
// fetchEndUsers,
// searchEndUsers,
"../../../redux/modules/admin/actions";
import { fetchModels } from "../../../redux/modules/partners/actions";
import { useSelector, useDispatch } from "react-redux";
import "../../../index.css";
import Modal from "./Modal";
import FaceIcon from "@mui/icons-material/Face";
import SearchField from "../pageElementes/SearchField";
import Pagination from "./Pagination";
import CheckBoxFull from "../primitives/CheckboxFull";
import CheckBox from "../primitives/Checkbox";
import { useModalContext } from "../../../SuccessModalContext";
import ModelCard from "../cards/ModelCard";

const ListElemet = (props) => {
  const [showModal, setShowModal] = useState(false);

  const { setOpenState } = useModalContext();

  const dispatch = useDispatch();

  const [alreadyChecked, setAlreadyChecked] = useState([]);
  const [unchecked, setUchecked] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const callbackAssignment = () => {
    dispatch(updateUserData());
    // dispatch(fetchEndUsers(0, props.clients.total));

    setOpenState(true);
  };
  const search = (input, filters) => {
    setSearchValue(input);
    // if (input === "" || input === undefined) {
    //   dispatch(fetchEndUsers(0, props.totalClients));
    // } else {
    //   dispatch(searchEndUsers(input, 0));
    // }
  };

  useEffect(() => {
    if (showModal) {
      setAlreadyChecked([]);
      setUchecked([]);
      const tempArray = [];

      props.clients.users.map((item) => {
        const checked = item.models3D.includes(props.model.id);

        if (checked) {
          tempArray.push(item.id);
        }
      });
      setAlreadyChecked(tempArray);
    }
  }, [showModal]);

  const handleClickAssignments = (userId) => {
    const index = alreadyChecked.indexOf(userId);
    if (index > -1) {
      setAlreadyChecked((prev) => prev.filter((e) => e !== userId));
      setUchecked([...unchecked, userId]);
    } else {
      setAlreadyChecked([...alreadyChecked, userId]);
      setUchecked((prev) => prev.filter((e) => e !== userId));
    }
  };

  const submit = () => {
    dispatch(
      assignModel(
        {
          currentUser: props.model.userId.id,
          modelId: props.model.id,
          endUserIds: alreadyChecked,
        },
        callbackAssignment
      )
    );
    dispatch(
      removeAssignment(
        {
          currentUser: props.model.userId.id,
          modelId: props.model.id,
          endUserIds: unchecked,
        },
        callbackAssignment
      )
    );
    setShowModal(false);
  };
  return (
    <div key={props.index}>
      <ModelCard
        model={props.model}
        link={`/models/${props.model.id}`}
        noAction={true}
        // modifiable={true}
        // modalAction={() => setShowModal(true)}
      />

      <Modal
        title={`Assign model`}
        visibility={showModal}
        setVisibility={setShowModal}
        action={() => submit()}
      >
        {/* <div className="w-full my-2 p-2 relative"> */}

        <SearchField
          width="w-full"
          action={search}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />

        {showModal &&
          props.clients.users &&
          props.clients.users.length > 0 &&
          props.clients.users.map((item, i) => {
            const checked = alreadyChecked.includes(item.id);

            return (
              <div
                className={`w-full flex items-center my-2 p-2 relative cursor-pointer ${
                  checked && "bg-primary bg-opacity-20"
                }`}
                key={i}
                onClick={() => handleClickAssignments(item.id, checked)}
              >
                <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full">
                  {item.profilePicture ? (
                    <img
                      src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                    />
                  ) : (
                    <FaceIcon className="text-white" />
                  )}
                </div>
                <div className="text-sm font-medium text-gray-900 ml-4">
                  {item.name}
                  <div className="text-sm mr-4 text-gray-500">
                    {item.phone} {item.email}
                  </div>
                </div>
                {checked ? (
                  <div className="absolute right-0">
                    <CheckBoxFull />
                  </div>
                ) : (
                  <div className="absolute right-0">
                    <CheckBox />
                  </div>
                )}
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

const ListRow = (props) => {
  return props.row.map((listElement, index) => {
    return (
      <ListElemet
        key={index}
        clients={props.clients}
        model={listElement}
        index={index}
        images={props.images}
        totalClients={props.totalClients}
      />
    );
  });
};

const ModelsListPartner = (props) => {
  const [listData, setListData] = React.useState([]);
  const [images] = React.useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalClients, setTotalClients] = useState(12);
  const models = useSelector((state) => state.partnersData);
  const dispatch = useDispatch();
  const { endUsers } = useSelector((state) => state.adminData);
  const [filters, setFilters] = useState({
    rooms: "",
    bathrooms: "",
    materials: "",
  });

  React.useEffect(() => {
    dispatch(errorClear());
    // dispatch(fetchModels({ search: "" }));
    // dispatch(fetchEndUsers(0, endUsers.total));
    setTotalClients(endUsers.total);
  }, []);

  React.useEffect(() => {
    if (models && models.models) {
      setListData(
        formatData(
          props.slice ? models.models.models.slice(0, 6) : models.models.models
        )
      );
    }
  }, [models.models]);

  const formatData = (listData) => {
    const result = [];
    if (listData && listData.length > 0)
      for (let i = 0; i < listData.length; i++) {
        const tempArray = [];
        tempArray.push(listData[i]);

        // if (listData[i]) tempArray.push(listData[i]);
        // if (listData[i + 1]) tempArray.push(listData[i + 1]);
        // if (listData[i + 2]) tempArray.push(listData[i + 2]);

        result.push(tempArray);
      }

    return result;
  };
  const search = (input, filters) => {
    setPage(1);
    dispatch(fetchModels({ search: input, filters: filters, page: 0 }));
    // if (input === "" || input === undefined) {
    //   dispatch(fetchModelsAdmin(page - 1));
    // } else {
    //   dispatch(searchModels(input, 0));
    // }
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(
        fetchModels({ search: searchValue, filters: filters, page: page - 1 })
      );
    } else {
      dispatch(fetchModels({ page: page - 1 }));
    }
  };

  return (
    <>
      <SearchField
        action={search}
        showFilterButton={true}
        setFilters={setFilters}
        filters={filters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
        {listData.map((item, i) => {
          return (
            <ListRow
              row={item}
              images={images}
              key={i}
              clients={endUsers}
              totalClients={totalClients}
            />
          );
        })}
      </div>
      <Pagination
        pageSize={models.models.pageSize}
        totalPages={models.models.totalPages}
        total={models.models.total}
        page={page}
        setPage={handlePage}
      />
    </>
  );
};
export default ModelsListPartner;
