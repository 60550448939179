export const UPLOAD_MODEL = "UPLOAD_MODEL";
export const UPLOAD_MODEL_LOADING = "UPLOAD_MODEL_LOADING";
export const UPLOAD_MODEL_HAS_ERROR = "UPLOAD_MODEL_HAS_ERROR";
export const UPLOAD_MODEL_SUCCESS = "UPLOAD_MODEL_HAS_SUCCESS";
export const FETCH_MODELS = "FETCH_MODELS_CLIENTS";
export const FETCH_MODELS_LOADING = "FETCH_MODELS_LOADING";
export const FETCH_MODELS_HAS_ERROR = "FETCH_MODELS_HAS_ERROR";
export const FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS";
export const FETCH_MODELS_IMAGES = "FETCH_MODELS_IMAGES";
export const ASSIGN_MODEL = "ASSIGN_MODEL";
export const REMOVE_ASSIGNMENT = "REMOVE_ASSIGNMENT";
export const MODELS_ERROR_CLEAR = "MODELS_ERROR_CLEAR";
export const UPDATE_MODEL_SUCCESS = "UPDATE_MODEL_SUCCESS";
export const UPDATE_MODEL_COVER_SUCCESS = "UPDATE_MODEL_COVER_SUCCESS";
export const FETCH_SINGLE_MODEL_SUCCESS = "FETCH_SINGLE_MODEL_SUCCESS";
export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS";
export const GET_CATEGORIES_SECOND = "GET_CATEGORIES_SECOND";
export const GET_CATEGORIES_PARRENT = "GET_CATEGORIES_PARRENT";
export const ADD_MODEL_TO_FAVORITE_SUCCESS = "ADD_MODEL_TO_FAVORITE_SUCCESS";
export const ADD_MODEL_TO_FAVORITE_ERROR = "ADD_MODEL_TO_FAVORITE_ERROR";
export const ADD_MODEL_TO_FAVORITE_LOADING = "ADD_MODEL_TO_FAVORITE_LOADING";
export const REMOVE_MODEL_TO_FAVORITE_LOADING =
  "REMOVE_MODEL_TO_FAVORITE_LOADING";
export const FETCH_END_USER_FAVORITES = "FETCH_END_USER_FAVORITES";
export const FETCH_END_USER_MODELS = "FETCH_END_USER_MODELS";
