import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import MainButton from "../buttons/MainButton";
import {
  endUserLogin,
  clearEndUserLogin,
  verifyCode,
} from "../../../redux/modules/endUserLogin/actions";
import { updateUserDataSuccess } from "../../../redux/modules/user/actions";
import { signinSuccess } from "../../../redux/modules/auth/actions";
const EndUserSigninForm = ({ title, subtitle, callback }) => {
  const [phone, setPhone] = React.useState("");
  const [code, setCode] = React.useState("");
  const [showErrors, setShowErrors] = React.useState(false);

  const { loginHasError, verifyCodeSuccess, loginSuccess } = useSelector(
    (state) => state.loginEndUser
  );

  React.useEffect(() => {
    loginSuccess && setShowCodeField(true);
  }, [loginSuccess]);

  React.useEffect(() => {
    setShowCodeField(false);
  }, [verifyCodeSuccess]);

  //   React.useEffect(() => {
  //     if (isAuthenticated) {
  //       navigate("/market-single-model-logedin/" + user.id);
  //     }
  //   }, [isAuthenticated]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(clearEndUserLogin());
  }, []);
  const [showCodeField, setShowCodeField] = React.useState(false);

  const signinCallback = (user) => {
    dispatch(updateUserDataSuccess(user));
    dispatch(signinSuccess(user));
    if (callback) callback();
  };

  const submit = (e) => {
    e.preventDefault();
    if (showCodeField === false) {
      dispatch(endUserLogin(phone));
    } else {
      dispatch(verifyCode(code, phone, signinCallback));
    }
  };

  const errorDisplay = () => {
    if (loginHasError.length > 0) {
      return loginHasError.map((error, i) => {
        return (
          <p key={i} className="text-xs text-red-500 ">
            {error.message}
          </p>
        );
      });
    }
  };
  React.useEffect(() => {
    if (loginHasError && loginHasError.length > 0) {
      setShowErrors(true);
    }
  }, [loginHasError]);
  return (
    <form
      className="w-full h-full flex justify-center  items-center flex-col"
      onSubmit={(e) => submit(e)}
    >
      <div className="z-10 w-full p-5 flex justify-center items-center flex-row">
        <div className="">
          <h2 className="font-bold text-center justify-center items-center text-black py-2.5">
            {title || "Sign in"}
          </h2>
        </div>
      </div>
      <p className="font-medium text-center text-fade leading-7 tracking-wide">
        {subtitle || "Sign in to XRETEH and continue"}
      </p>
      <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
        <LocalPhoneIcon />
        <input
          placeholder="Phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          disabled={showCodeField}
          type={"text"}
          className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
        />
      </div>

      {showCodeField && (
        <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
          <LockOutlinedIcon />
          <input
            type={"text"}
            placeholder="Code"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
          />
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {}}
              onMouseDown={() => {}}
            ></IconButton>
          </InputAdornment>
        </div>
      )}
      {showErrors && loginHasError.length > 0 && errorDisplay()}
      <div
        style={{ marginTop: "30px" }}
        className="flex justify-center items-center flex-col h-12 w-full my-4"
      >
        <MainButton type="submit" title="Login" width="sm:w-5/6" />
      </div>
    </form>
  );
};

export default EndUserSigninForm;
