import React, { useState, useEffect } from "react";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MainButton from "../../Components/ui/buttons/MainButton";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import {
  profileDatilsUpdate,
  passwordChange,
} from "../../redux/modules/auth/actions";
import {
  updateUserData,
  profilePictureUpload,
} from "../../redux/modules/user/actions";
// import { clearError } from "../../redux/modules/auth/actions";
import { useForm } from "react-hook-form";
import SuccessModal from "../../Components/ui/modals/SuccessModal";

let modalOpen = false;

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { user, userError } = useSelector((state) => state.userData);

  const [profile, setPicture] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChangeSuccess, setPasswordSuccess] = useState("");
  const [initData, setInitData] = useState({
    name: "",
    phone: "",
    company: "",
  });

  useEffect(() => {
    setInitData({
      name: user.name,
      phone: user.phone,
      company: user.company,
    });
    setValue("name", user.name);
    setValue("phone", user.phone);
    setValue("company", user.company);
  }, [user]);

  const setModalOpen = (value) => {
    modalOpen = value;
    setRerender(!rerender);
  };

  const [rerender, setRerender] = useState(false);

  const [formData] = useState(new FormData());
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPicture(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul>
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be image"
              : e.message}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileAccpetedItems = profile.map((file) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });
  const callback = () => {
    dispatch(updateUserData());
    setModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // clearError(clearError());
    dispatch(updateUserData());
    //
  }, []);
  useEffect(() => {
    if (profile.length) {
      formData.set("profilePicture", profile[0]);
    }

    profile.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [profile]);

  const callbackPassword = () => {
    setPasswordSuccess("Your password has been change");
    setModalOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitSwitch = (data) => {
    if (data.password) {
      dispatch(passwordChange(user.id, data.password, callbackPassword));
    }
    if (!shallowEqual(initData, data)) {
      dispatch(
        profileDatilsUpdate(
          user.id,
          {
            company: data.company,
            name: data.name,
            phone: data.phone,
          },
          callback
        )
      );
    }
    if (profile && profile.length > 0) {
      dispatch(profilePictureUpload(user.id, formData, callback));
    }
  };
  const backendErrors = () => {
    return userError.map((e) => (
      <li className="text-xs text-red-500" key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  return (
    <div className={"w-full flex-wrap"}>
      <SuccessModal
        visibility={modalOpen}
        setVisibility={setModalOpen}
        title={"The update operation succeeded"}
      />

      <PageHeader Icon={ManageAccountsIcon} />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden  bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Profile picture
            </h3>
            <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
              <div
                className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
                {...getRootProps()}
              >
                <p className="text-center px-2 py-1">
                  Drag & Drop files here or Browse Files
                </p>
                <p className="text-center px-2 py-1">File type: JPG, PNG </p>
                <input {...getInputProps()} />
                {fileRejectionItems}
                {fileAccpetedItems}
                <div className="w-6/12 sm:w-4/12  flex flex-wrap justify-center py-1">
                  <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                    {profile && profile.length > 0 ? (
                      <img
                        src={profile[0].preview}
                        alt="..."
                        className="w-full h-28 object-cover"
                      />
                    ) : user.profilePicture ? (
                      <img
                        // src={`data:image/png;base64,${
                        //   user.profilePicture && user.profilePicture
                        // }`}
                        src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                        alt="..."
                        className="w-full h-28 object-cover"
                      />
                    ) : (
                      <ImageIcon fontSize="large" className="text-white" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Email
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="email"
                    disabled={true}
                    type="email"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={user.email}
                    placeholder={"Emial"}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                ></p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="name"
                    type="text"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={user.name}
                    placeholder={"Name"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Company name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="company"
                    type="text"
                    // type={showPassword ? "text" : "password"}
                    defaultValue={user.company}
                    placeholder={"Company"}
                    rules={{
                      minLength: {
                        value: 3,
                        message: "The min length of the company name is 3",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.company && errors.company.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Phone
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="phone"
                    defaultValue={user.phone}
                    placeholder={"+CC XXX XXX XXX"}
                    type="text"
                    rules={{
                      pattern: {
                        value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                        message: "Please use a valid phone number",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.phone && errors.phone.message}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Password
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    control={control}
                    fieldName="password"
                    type={showPassword ? "text" : "password"}
                    // defaultValue={user.phone}
                    placeholder={"Password"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the password is 4",
                      },
                    }}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.password && errors.phone.password}
                </p>
                {passwordChangeSuccess !== "" && (
                  <div className="rounded-xl bg-green-100 px-2 py-1 text-green-700 flex justify-center">
                    {passwordChangeSuccess}
                  </div>
                )}
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Subscribed status
                </h3>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
                  <Input
                    type="text"
                    disabled={true}
                    control={control}
                    fieldName="status"
                    defaultValue={
                      user.role === "Admin"
                        ? user.role
                        : user.subscribed === "tierOne"
                        ? "Silver"
                        : user.subscribed === "tierTwo"
                        ? "Gold"
                        : "No"
                    }
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {/* {errors.phone && errors.phone.message} */}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2 flex justify-center  items-center">
            <MainButton
              action={handleSubmit(submitSwitch)}
              title="Save"
              width="w-2/6"
            />
          </div>
          <ul className="w-full flex justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
