import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import ClientsList from "../../Components/ui/pageElementes/ClientsList";
import { useDispatch, useSelector } from "react-redux";
import {
  addClient,
  fetchClients,
  searchClients,
} from "../../redux/modules/clients/actions";
import { fetchModels, searchModels } from "../../redux/modules/models/actions";
import { useForm, Controller } from "react-hook-form";
import Modal from "../../Components/ui/pageElementes/Modal";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import SearchField from "../../Components/ui/pageElementes/SearchField";
import Pagination from "../../Components/ui/pageElementes/Pagination";

import { useModalContext } from "../../SuccessModalContext";

const MyClients = () => {
  const dispatch = useDispatch();
  const { clients, hasError } = useSelector((state) => state.clientsData);
  const { models } = useSelector((state) => state.modelData);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [searchModelValue, setSearchModelValue] = useState("");
  const { setOpenState } = useModalContext();
  const callback = () => {
    dispatch(fetchClients());
    setOpenState(true);
  };
  const submit = (data) => {
    dispatch(addClient(data, callback));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchClients());
    dispatch(fetchModels(0, models.total));
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const validatePhone = (phone) => {
    return !clients.users.some((user) => user.phone === phone);
  };

  const search = (input) => {
    setSearchValue(input);
    setPage(1);

    if (input === "" || input === undefined) {
      dispatch(fetchClients(page - 1));
    } else {
      dispatch(searchClients(input, 0));
    }
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(searchClients(searchValue, page - 1));
    } else {
      dispatch(fetchClients(page - 1));
    }
  };
  const callbackAssignment = () => {
    dispatch(fetchClients());
    // dispatch(fetchModelsAdmin());
    setOpenState(true);
  };
  const modelsSearch = (value) => {
    dispatch(searchModels(value, {}, 0, models.total));
  };
  const backendErrors = () => {
    if (!hasError) {
      return;
    }

    return hasError.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => setShowModal(true)}
        buttonTitle="Add client"
      />
      <SearchField
        action={search}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <ClientsList
        data={clients.users}
        elementRoute="/my-client"
        assignModel={true}
        models={models}
        aUser={true}
        callbackAssignment={callbackAssignment}
        modelsSearch={modelsSearch}
        modelsSearchValue={{
          value: searchModelValue,
          setValue: setSearchModelValue,
        }}
      />
      <Pagination
        pageSize={clients.pageSize}
        totalPages={clients.totalPages}
        total={clients.total}
        page={page}
        setPage={handlePage}
      />
      <Modal
        title={`Add client`}
        visibility={showModal}
        setVisibility={setShowModal}
      >
        <form>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl `}
              >
                <Input
                  control={control}
                  fieldName="name"
                  type="text"
                  // type={showPassword ? "text" : "password"}

                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                    required: {
                      value: true,
                      message: "Please add the clients name ",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Email
              <span className="text-xs text-red-500">*</span>
            </h3>
            <span className="text-xs text-blue-400">Mandatory field</span>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl `}
            >
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <input
                      onChange={(e) => onChange(e)}
                      placeholder="Email"
                      className="flex flex-1 appearance-none  w-1/2 py-3 leading-tight focus:outline-none"
                    />
                  );
                }}
                name="email"
                rules={{
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    message: "Please use a valid email address",
                  },
                  required: {
                    value: true,
                    message: "Please add an email address",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.email && errors.email.message}
            </p>
          </div>

          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full  px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <br />
              <span className="text-xs text-blue-400">
                *Please add the country prefix: +CC XXX XXX XXX
              </span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl `}
              >
                <Input
                  control={control}
                  fieldName="phone"
                  placeholder={"+CC XXX XXX XXX"}
                  type="text"
                  rules={{
                    required: {
                      value: true,
                      message: "Please add the clients phone number",
                    },
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                    validate: {
                      checkPhone: (v) =>
                        validatePhone(v) || "Phone number already used",
                    },
                  }}
                />
              </div>

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
          </div>
          <ul className="w-full flex justify-center items-center">
            {backendErrors()}
          </ul>
          <div className="w-full p-2">
            <MainButton action={handleSubmit(submit)} title="Add client" />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default MyClients;
