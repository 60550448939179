import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFavorites } from "../../redux/modules/models/actions";
import InternalTopNavbar from "../../Components/ui/nav/InternalTopNavbar";
import SearchField from "../../Components/ui/pageElementes/SearchField";
import ModelsListMarket from "../../Components/ui/pageElementes/ModelsListMarket";
import { BasicMenu } from "../marketPlace";
const MyFavoritesEndUser = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortValue, setSortValue] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const { favorites } = useSelector((state) => state.modelData);
  const { user } = useSelector((state) => state.userData);
  // useEffect(() => {
  //   dispatch(
  //     fetchFavorites({
  //       page: 0,
  //       sortBy: sortBy,
  //       sortValue: sortValue,
  //     })
  //   );
  // }, []);
  const search = (input, filters) => {
    setPage(1);
    dispatch(
      fetchFavorites({
        sortBy: sortBy,
        sortValue: sortValue,
        search: input,
        filters: filters,
        page: 0,
      })
    );
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(
        fetchFavorites({
          search: searchValue,
          page: page - 1,
          sortBy: sortBy,
          sortValue: sortValue,
        })
      );
    } else {
      dispatch(
        fetchFavorites({
          page: page - 1,

          sortBy: sortBy,
          sortValue: sortValue,
        })
      );
    }
  };
  const handleSort = (by, value) => {
    setSortBy(by);
    setSortValue(value);
  };
  return (
    <div className="w-full flex justify-center  items-center flex-col">
      <InternalTopNavbar title="My favorites" />

      <div className="w-4/5 flex items-center flex-col">
        <div className="w-full flex justify-between items-center">
          <div className="w-full">
            <SearchField
              showFilterButton={false}
              action={search}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              sortBy={sortBy}
              sortValue={sortValue}
              width="sm:w-3/6"
            />
          </div>
          <div className="w-1/4 mx-4 flex justify-end">
            <BasicMenu
              handleSort={handleSort}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
        <ModelsListMarket
          user={user}
          fetchModels={true}
          isMarket={true}
          page={page}
          search={search}
          handlePage={handlePage}
          handleSort={handleSort}
          models={favorites}
        />
      </div>
    </div>
  );
};

export default MyFavoritesEndUser;
