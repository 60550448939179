import React from "react";
import { Link, NavLink } from "react-router-dom";
// import houseOneLevel from "../../../staticfiles/casa1.png";
// import houseTwoLevels from "../../../staticfiles/casa2.png";
import Slider from "@mui/material/Slider";
// import logo from "../../../staticfiles/Logo.png";
import logoMarket from "../../../assets/images/logo-market.png";
const SidebarMarketPlace = (props) => {
  // const [sliderValues, setSliderValues] = useState([1, 1000]);
  // const props.handleChange = (e) => {};
  function valuetext(value) {
    return `${value}`;
  }

  const showCategories = () => {
    if (props.categories && props.categories.categories?.length > 0) {
      return props.categories?.categories.map((category, i) => {
        return (
          <div
            key={i}
            className="flex justify-center items-center flex-col cursor-pointer m-2"
            onClick={props.handleChange("category", { value: category.id })}
          >
            <div className=" w-20 shadowBoxFull  h-20 flex justify-center items-center">
              <img
                src={`${process.env.REACT_APP_API}${category.image.usePath}/${category.image.filename}`}
                className={"color-black"}
              />
            </div>
            <span
              className={`${
                props.filters.category === category.id &&
                "text-primary font-bold"
              }`}
            >
              {category.name}
            </span>
          </div>
        );
      });
    }
  };
  return (
    !props.hideSideMenu && (
      <>
        <div
          style={{ zIndex: "99" }}
          className={`min-h-screen fixed top-0 bottom-0 lg:left-0 ${props.showSidebar} overflow-y-auto md:overflow-hidden flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64   px-6 transition-all duration-300`}
        >
          <NavLink to="/models" className=" text-center w-full inline-block">
            <div className="logo-container">
              <img src={logoMarket} className={"w-32 lg:mx-12"} />
            </div>
          </NavLink>
          <div className="flex flex-col items-stretch min-h-full flex-nowrap px-0 relative py-2">
            <div className="flex flex-col flex-1">
              <div className="flex items-center flex-row justify-evenly">
                {/* <div className="ml-4 mt-2 w-20 shadowBoxFull  h-20 flex justify-center items-center">
                  <img src={houseOneLevel} className={"color-black"} />
                </div>
                <div className="ml-4 mt-2 w-20 shadowBoxFull  h-20 flex justify-center items-center">
                  <img src={houseTwoLevels} />
                </div> */}
                {showCategories()}
              </div>

              <div className="mt-4">
                <div className="p-2 font-bold">Filter by rooms</div>
                <div className="border-2 rounded-full w-full p-2 mb-4 flex ">
                  <input
                    onChange={props.handleChange("rooms")}
                    placeholder="Number of rooms"
                    type={"text"}
                    className={"focus:outline-none w-full ml-2"}
                    value={props.filters.rooms}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="p-2 font-bold">Filter by bathrooms</div>
                <div className="border-2 rounded-full w-full p-2 mb-4 flex ">
                  <input
                    onChange={props.handleChange("bathrooms")}
                    placeholder="Number of bathrooms"
                    type={"text"}
                    className={"focus:outline-none w-full ml-2"}
                    value={props.filters.bathrooms}
                  />
                </div>{" "}
              </div>
              <div className="mt-4">
                <div className="p-2 font-bold">Area/sqm</div>
                <div className=" p-2 flex ">
                  <Slider
                    size="small"
                    onChange={props.handleChange("area")}
                    // defaultValue={50}
                    min={props.sliderMinMax ? [0] : 0}
                    max={props.sliderMinMax ? [1] : 1000}
                    getAriaValueText={valuetext}
                    // valueLabelDisplay="auto"
                    valueLabelDisplay="auto"
                    getAriaLabel={() => "Minimum distance shift"}
                    disableSwap
                    value={props.sliderValues}
                  />
                </div>
                <div className="flex">
                  <div className="border-2 rounded-lg w-full p-2 mx-2 text-center cursor-normal bg-gray-100">
                    {props.sliderValues[0]}
                  </div>
                  <div className="border-2 rounded-lg w-full p-2 mx-2 text-center cursor-normal bg-gray-100">
                    {props.sliderValues[1]}
                  </div>
                </div>
                <div
                  div
                  className="w-full mt-4 flex justify-end text-primary text-sm  cursor-pointer"
                  onClick={() => props.clearFilters()}
                >
                  Clear filters
                </div>
              </div>
              {!props.isAuthenticated && !props.marketUser && (
                <Link
                  className="text-primary font-medium text-right"
                  to="/sign-in-end-user"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SidebarMarketPlace;
