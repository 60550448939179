import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FaceIcon from "@mui/icons-material/Face";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SquareButton from "../buttons/SquareButton";
import Modal from "./Modal";
import ListLongCard from "../cards/ListLongCard";
import {
  assignModelsToSingleUser,
  removeAssignmentToSingleUser,
} from "../../../redux/modules/models/actions";
import SearchField from "./SearchField";
const ClientsList = ({
  data,
  admin,
  elementRoute,
  assignModel,
  models,
  callbackAssignment,
  aUser,
  modelsSearch,
  modelsSearchValue,
  partner,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [endUserId, setEndUserId] = useState({
    id: "",
    index: "",
  });
  const [alreadyChecked, setAlreadyChecked] = useState([]);
  const [alreadyCheckedCurrentUser, setAlreadyCheckedCurrentUser] = useState(
    []
  );
  const [unchecked, setUchecked] = useState([]);
  const [uncheckedCurrentUser, setUcheckedCurrentUser] = useState([]);
  const handleClickAssignments = (modelId, userId) => {
    const index = alreadyChecked.indexOf(modelId);
    if (index > -1) {
      setAlreadyChecked((prev) => prev.filter((e) => e !== modelId));
      setAlreadyCheckedCurrentUser((prev) => prev.filter((e) => e !== userId));
      setUchecked([...unchecked, modelId]);
      setUcheckedCurrentUser([...uncheckedCurrentUser, userId]);
    } else {
      setAlreadyChecked([...alreadyChecked, modelId]);
      setAlreadyCheckedCurrentUser([...alreadyCheckedCurrentUser, userId]);
      setUchecked((prev) => prev.filter((e) => e !== modelId));
      setUcheckedCurrentUser((prev) => prev.filter((e) => e !== userId));
    }
  };

  const submit = () => {
    dispatch(
      assignModelsToSingleUser(
        {
          currentUser: alreadyCheckedCurrentUser,
          modelIds: alreadyChecked,
          endUserId: endUserId.id,
        },
        callbackAssignment
      )
    );

    if (unchecked && unchecked.length > 0) {
      dispatch(
        removeAssignmentToSingleUser(
          {
            currentUser: uncheckedCurrentUser,
            modelIds: unchecked,
            endUserId: endUserId.id,
          },
          callbackAssignment
        )
      );
    }
    setShowModal(false);
    setEndUserId({ id: "", index: "" });
  };
  const clickOnUser = (id, index) => {
    setEndUserId({ id: id, index: index });
    setShowModal(true);
  };
  const closeModal = () => {
    setEndUserId({ id: "", index: "" });
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      setAlreadyChecked([]);
      setAlreadyCheckedCurrentUser([]);
      setUchecked([]);
      setUcheckedCurrentUser([]);
      const tempArray = [];
      const tempUser = [];

      models.models.map((item, i) => {
        let checked;
        if (aUser) {
          checked =
            data[endUserId.index].models3D.filter((e) => e.id === item.id)
              .length > 0;
        } else {
          checked = data[endUserId.index].models3D.includes(item.id);
        }
        //

        if (checked) {
          tempArray.push(item.id);
          tempUser.push(item.userId.id);
        }
      });

      setAlreadyChecked(tempArray);
      setAlreadyCheckedCurrentUser(tempUser);
    }
  }, [showModal, endUserId]);
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadowBox overflow-hidden ">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-textTitle uppercase tracking-wider"
                  >
                    {admin ? "User" : "Client name"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-textTitle uppercase tracking-wider"
                  >
                    Join date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-textTitle uppercase tracking-wider"
                  >
                    {admin
                      ? partner
                        ? "Related architects"
                        : "Related end users"
                      : " Assigned models"}
                  </th>

                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Options</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                              {item.profilePicture ? (
                                <img
                                  src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                                  alt={item.name}
                                  className="object-cover w-full h-full"
                                />
                              ) : (
                                <FaceIcon className="text-white " />
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {item.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {item.phone} {item.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {moment(item.createdAt).format("LL")}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {admin
                            ? partner
                              ? item.connectedAccounts.length
                              : item.connectedEndUsers.length
                            : item.models3D.length}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex flex-1 justify-center items-center">
                          {assignModel && (
                            <SquareButton
                              action={() => clickOnUser(item.id, index)}
                              title="Assign models"
                              fill={true}
                            />
                          )}
                          <SquareButton
                            action={() => {
                              if (elementRoute) {
                                navigate(`${elementRoute}/${item.id}`);
                              }
                            }}
                            title="Details"
                            fill={false}
                          />

                          {/* <Button variant="outlined"></Button> */}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* <div>Pagination</div> */}
          </div>
        </div>
        {assignModel && (
          <Modal
            title={`Assign model`}
            visibility={showModal}
            setVisibility={closeModal}
            action={() => submit()}
          >
            {modelsSearch && (
              <SearchField
                width="w-full"
                action={modelsSearch}
                searchValue={modelsSearchValue.value}
                setSearchValue={modelsSearchValue.setValue}
              />
            )}
            {models.models &&
              models.models.length > 0 &&
              models.models.map((item, i) => {
                const checked = alreadyChecked.includes(item.id);
                return (
                  <ListLongCard
                    id={item.id}
                    name={item.name}
                    // subname={}
                    handleClick={() =>
                      handleClickAssignments(item.id, item.userId.id)
                    }
                    key={i}
                    image={`${process.env.REACT_APP_API}/models3D/content/image/${item.id}/${item.updatedAt}`}
                    checked={checked}
                  />
                );
              })}
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ClientsList;
