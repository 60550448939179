import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleUser,
  fetchUsers,
  singleUserUpdate,
  removeAUser,
} from "../../redux/modules/partners/actions"; // fetchSingleUser,
// singleUserUpdate,
// changeUserSubscription,
// removeAUser,
// fetchUsers,
// fetchLatestUsers,

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm, Controller } from "react-hook-form";
import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { breakConnection } from "../../redux/modules/clients/actions";
import { useModalContext } from "../../SuccessModalContext";
const PartnerSingleUser = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleUser, error } = useSelector((state) => state.partnersData);
  const { setOpenState } = useModalContext();
  const [removeModal, setRemoveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleUser(id));
  }, []);

  const navigate = useNavigate();
  const callback = () => {
    dispatch(fetchSingleUser(id));
    // dispatch(fetchLatestUsers());
    setOpenState(true);
    // navigate("/architects");
  };

  const [currentDeletedUser, setCurrentDeletedUser] = useState(null);

  const closeRemoveUserModal = () => {
    setCurrentDeletedUser(null);
  };

  const submit = (data) => {
    // if (data.subscribed && data.subscribed !== values.subscribed) {
    //   dispatch(changeUserSubscription(id, data.subscribed));
    // }
    dispatch(
      singleUserUpdate(
        singleUser.user?.id,
        { company: data.company, name: data.name, phone: data.phone },
        callback
      )
    );
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    reset(singleUser.user);
  }, [singleUser]);
  const callbackRemove = () => {
    dispatch(fetchUsers({ search: "" }));
    navigate("/users");
    setOpenState("The user has been removed");
  };
  const removeSubmit = () => {
    dispatch(removeAUser(id, callbackRemove));
  };

  const removeUserSubmit = () => {
    // dispatch(removeAUser(currentDeletedUser.id, callBackRemoveUser));
    dispatch(
      breakConnection(
        { endUser: currentDeletedUser.id, currentUser: id },
        callBackRemoveUser
      )
    );
    callBackRemoveUser();
  };

  const callBackRemoveUser = () => {
    dispatch(fetchSingleUser(id));
    closeRemoveUserModal();
  };
  const backendErrors = () => {
    return error.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/users"}
        Profile={
          singleUser.user?.profilePicture ? (
            <img
              src={`${process.env.REACT_APP_API}/content/${singleUser.user.id}/${singleUser.user.updatedAt}`}
              alt={singleUser.user.name}
              className="w-full object-cover"
            />
          ) : (
            <FaceIcon className="text-white " />
          )
        }
        buttonIcon={<DeleteForeverIcon />}
        buttonTitle={
          singleUser?.user?.role === "Partner"
            ? "Remove this partner"
            : "Remove this architect"
        }
        action={() => setRemoveModal(true)}
        buttonSecondary={true}
        title={
          singleUser.user?.name ? singleUser.user?.name : singleUser.user?.email
        }
      />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="email"
                  disabled={true}
                  type="email"
                  // type={showPassword ? "text" : "password"}
                  defaultValue={singleUser.user?.email}
                  placeholder={"Email"}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              ></p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  control={control}
                  fieldName="name"
                  type="text"
                  // type={showPassword ? "text" : "password"}
                  defaultValue={singleUser.user?.name}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Company name
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="company"
                type="text"
                // type={showPassword ? "text" : "password"}
                defaultValue={singleUser.user?.company}
                placeholder={"Company"}
                rules={{
                  minLength: {
                    value: 3,
                    message: "The min length of the company name is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.company && errors.company.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Phone
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                control={control}
                fieldName="phone"
                type="text"
                defaultValue={singleUser.user?.phone}
                placeholder={"+CC XXX XXX XXX"}
                rules={{
                  pattern: {
                    value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                    message: "Please use a valid phone number",
                  },
                }}
              />
            </div>

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.phone && errors.phone.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Subscribed status
            </h3>
            <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
              {" "}
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <select
                      className="flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none"
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      defaultValue={singleUser.user?.subscribed}
                    >
                      <option value="No">No</option>
                      <option value="tierOne">Silver</option>
                      <option value="tierTwo">Gold</option>
                    </select>
                  );
                }}
                defaultValue={singleUser.user?.subscribed}
                name="subscribed"
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Connected End Users
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  // No = "no",
                  // TierOne = "tierOne",
                  // TierTwo = "tierTwo",
                  return (
                    <input
                      ref={ref}
                      disabled={true}
                      value={singleUser.user?.connectedEndUsers?.length}
                      type="text"
                      placeholder="New name"
                      className="w-full py-3"
                      // className="uploadModelForm-textField bigTextField"
                    />
                  );
                }}
                defaultValue={singleUser.user?.connectedEndUsers?.length}
                name="connectedEndUsers"
              />
            </div>
          </div>
          <div className="w-full p-2">
            <MainButton action={handleSubmit(submit)} title="Save" />
          </div>{" "}
          <ul className="w-full flex flex-col justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>

      <div className="py-2 align-middle inline-block min-w-full">
        <div className="shadowBox overflow-hidden bg-white  px-2 sm:px-6 lg:px-8">
          <div className="flex justify-center text-center p-2 font-bold text-xl">
            {" "}
            Models
          </div>
          {singleUser && singleUser.models && singleUser.models.length ? (
            <ModelsList
              listData={[
                singleUser ? (singleUser.models ? singleUser.models : []) : [],
              ]}
              modalShow={() => {}}
            />
          ) : (
            <Empty info="This user has no 3D models" />
          )}
        </div>
        <RemoveModal
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this user?"
          description={`This action is irreversible.`}
          buttonText="Remove architect"
        />

        <RemoveModal
          buttonText="Remove user"
          visibility={currentDeletedUser !== null}
          setVisibility={closeRemoveUserModal}
          submitAction={removeUserSubmit}
          title="Are you sure you want to remove this user?"
          description={currentDeletedUser && `This action is irreversible.`}
        />
        <SuccessModal
          visibility={showSuccessModal}
          setVisibility={setShowSuccessModal}
          title={"The update operation succeeded"}
        />
      </div>
    </div>
  );
};

export default PartnerSingleUser;
