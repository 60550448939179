import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import BathtubIcon from "@mui/icons-material/Bathtub";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ViewInAr from "@mui/icons-material/ViewInAr";
import MainButton from "../buttons/MainButton";
import AddIcon from "@mui/icons-material/Add";
import FaceIcon from "@mui/icons-material/Face";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.min.css";

const ModelCard = ({
  model,
  modifiable,
  modalAction,
  link,
  simpleAction,
  isFavorite,
  showAction = true,
  aUser,
  noAction = false,
}) => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

  return (
    <div className="px-4 max-w-xs my-4" style={{ minWidth: "21rem" }}>
      <div
        className={
          " relative w-full flex items-center justify-evenly flex-col bg-white rounded-xl shadow-md   overflow-hidden"
        }
      >
        {noAction ? (
          <></>
        ) : !showAction && model.arReady ? (
          <div className={"absolute  h-10 top-5 right-5 z-20"}>
            <div
              onClick={simpleAction}
              className="bg-white w-10 h-10 flex justify-center items-center rounded-full cursor-pointer"
            >
              <FavoriteBorderIcon color={"error"} />
            </div>
          </div>
        ) : (
          <div className={"absolute  h-10 top-5 right-5 z-20"}>
            {modifiable ? (
              <MainButton
                Icon={<AddIcon className="text-white" />}
                action={modalAction}
                title="Assign this model"
                extraClasses={"text-xs"}
                padding={"px-2 py-1"}
              />
            ) : !aUser && model.arReady ? (
              isFavorite === model.id ? (
                <div
                  onClick={simpleAction}
                  className="bg-white w-10 h-10 flex justify-center items-center rounded-full cursor-pointer"
                >
                  <FavoriteIcon color={"error"} />
                </div>
              ) : (
                <div
                  className="bg-white w-10 h-10 flex justify-center items-center rounded-full cursor-pointer"
                  onClick={simpleAction}
                >
                  <FavoriteBorderIcon color={"error"} />
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        )}
        {modifiable || noAction ? (
          <div
            className={
              "absolute w-10 h-10 top-5 left-5 rounded-full overflow-hidden bg-primary flex justify-center items-center z-20"
            }
          >
            {model.userId && model.userId.profilePicture ? (
              <img
                src={`${process.env.REACT_APP_API}${model.userId.profilePicture.usePath}/${model.userId.profilePicture.filename}`}
                alt={model.userId.company}
                className="object-cover w-full h-full"
              />
            ) : (
              <FaceIcon className="text-white " />
            )}
          </div>
        ) : (
          <></>
        )}
        <Link to={link} className="w-full">
          <div className={" w-full"}>
            <div className="w-full h-60">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                navigation
              >
                {model.coverImages && model.coverImages.length > 0 ? (
                  model.coverImages.map((image, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <img
                          src={`${process.env.REACT_APP_API}${image.usePath}/${image.filename}`}
                          className="swiper-lazy object-cover relative h-60 w-full"
                          alt={model.name}
                        />
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <SwiperSlide>
                    <img
                      src={`${process.env.REACT_APP_API}/models3D/content/image/${model.id}/${model.updatedAt}`}
                      className="object-cover relative h-60 w-full"
                      alt={model.name}
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
            <div
              className={
                " text-white text-lg font-bold w-full text-center px-2 truncate"
              }
              style={{ background: "rgba(0, 0, 0, 0.4)" }}
            >
              <span>{model.name}</span>
            </div>
          </div>
          <div className={"flex justify-evenly items-center p-2 w-full"}>
            <span className={"flex justify-center items-center"}>
              <BedroomParentIcon />
              <span>{model.rooms}</span>
            </span>
            <span className={"flex justify-center items-center"}>
              <BathtubIcon />
              <span>{model.bathrooms}</span>
            </span>
            <span className={"flex justify-center items-center"}>
              <SquareFootIcon />
              <span>
                {model.area} m<sup>2</sup>{" "}
              </span>
            </span>
            <span className={"flex justify-center items-center"}>
              <span className={"relative w-full"}>
                {model?.arReady ? (
                  <ViewInAr className="text-green" />
                ) : (
                  <span className="linethrough">
                    <ViewInAr className="text-gray-500 " />
                  </span>
                )}
              </span>
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ModelCard;
