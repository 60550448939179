import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import SidebarAdmin from "../../ui/nav/SidebarAdmin";
import { useSelector, useDispatch } from "react-redux";
import Statistics from "../../ui/pageElementes/Statistics";

import { fetchLatestUsers } from "../../../redux/modules/admin/actions";
import Version from "../../ui/primitives/Version";
import Footer from "../../ui/nav/Footer";
const AdminDashboard = () => {
  const dispatch = useDispatch();
  const {
    user: { role },
  } = useSelector((state) => state.auth);
  const { latestUsers } = useSelector((state) => state.adminData);
  const users = latestUsers?.users ? latestUsers.users : [];
  const [useUsers, setUseUsers] = useState([]);
  useEffect(() => {
    dispatch(fetchLatestUsers());
  }, []);
  useEffect(() => {
    if (role === "Admin") {
      if (users && users.length > 0) {
        const arr = users.slice(0, 5);
        setUseUsers(arr);
      }
    }
  }, [role, users]);

  return (
    <div className="p-2">
      <SidebarAdmin />
      <div className="lg:ml-64 ">
        <div className="flex flex-col xl:flex-row w-auto">
          <Outlet />

          <div className="flex flex-col md:flex-row xl:flex-col justify-center items-center xl:justify-start xl:items-start my-4 xl:my-0">
            <Statistics
              users={useUsers}
              title={
                role === "Admin" ? "Latest created architects" : "Your clients"
              }
            />
          </div>

          {/* <Analytics /> */}
        </div>
        <Footer />
      </div>
      <Version />
    </div>
  );
};

export default AdminDashboard;
