import { useRef, useEffect, useCallback } from "react";

function useInterval({ duration, callback }) {
  const intervalRef = useRef(null);
  useEffect(() => {
    intervalRef.current = setInterval(callback, duration);
    return () => clearInterval(intervalRef.current);
  }, []);
  const stop = useCallback(() => {
    clearInterval(intervalRef.current);
  }, []);
  return stop;
}

export default useInterval;
