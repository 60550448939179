import React, { useState } from "react";
import MainButton from "../ui/buttons/MainButton";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import {
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import circlesRight from "../../assets/images/landing-page/circles.png";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

const ContactSection = () => {
  const [response, setResponse] = useState("");
  const submitMail = (data) => {
    axios
      .post(`${process.env.REACT_APP_API}/contact-mail`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data.message);
      });
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  console.log(errors);
  return (
    <form id="contact" className="w-full mt-24">
      <div
        className="relative flex flex-col w-full lg:w-4/5 mx-auto space-y-24 px-4 lg:px-48 text-center bg-gray-100 rounded-b-xl pb-16"
        style={{ borderTopRightRadius: "8em", borderTopLeftRadius: "8em" }}
      >
        <div className="absolute hidden lg:flex bottom-0 z-0 right-0 opacity-20">
          <img src={circlesRight}></img>
        </div>

        <div className="relative w-full h-16">
          <h1
            className="relative lg:absolute text-5xl font-bold w-full lg:mt-8"
            style={{ zIndex: 1 }}
          >
            Have a question? Send us a message!
          </h1>
          <h1 className="absolute block text-8xl text-gray-200 font-bold w-full left-0 right-0 top-0 bottom-0  text-center">
            CONTACT
          </h1>
        </div>
        <div className="flex flex-col w-full lg:w-3/4 m-auto">
          <div className="flex flex-row lg:space-x-16">
            <div className="w-full">
              <div
                className={`border-2 rounded-full w-full p-2 mb-4 flex bg-white items-center`}
              >
                <EmailIcon />

                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <input
                        onChange={(e) => onChange(e)}
                        placeholder="Email"
                        className="flex flex-1 appearance-none  w-1/2 ml-5 leading-tight focus:outline-none"
                      />
                    );
                  }}
                  name="email"
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please use a valid email address",
                    },
                    required: {
                      value: true,
                      message: "Please add an email address",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.email && errors.email.message}
              </p>
            </div>
            <div className="w-full">
              <div
                className={`border-2 rounded-full w-full p-2 mb-4 flex bg-white items-center`}
              >
                <LocalPhoneIcon />

                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <input
                        onChange={(e) => onChange(e)}
                        placeholder="Phone"
                        className="flex flex-1 appearance-none  w-1/2 ml-5 leading-tight focus:outline-none"
                      />
                    );
                  }}
                  name="phone"
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                    required: {
                      value: true,
                      message: "Please add a phone number",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
          </div>

          <div>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <TextareaAutosize
                    className="textArea"
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="A few words about your client..."
                    onChange={(e) => onChange(e)}
                  />
                );
              }}
              name="description"
              rules={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.description && errors.description.message}
            </p>
          </div>
          <div className="flex flex-row w-full flex-none">
            <div>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <FormControlLabel
                      label={
                        <Typography className="text-left">
                          Continuing means you agree to the{" "}
                          <Link
                            className="font-bold text-black no-underline"
                            to="/terms"
                          >
                            Terms and Conditions
                          </Link>
                          , and our{" "}
                          <Link
                            className="font-bold text-black no-underline"
                            to="/privacy"
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                      control={
                        <Checkbox
                          onChange={(e) => onChange(e.target.checked)}
                        ></Checkbox>
                      }
                    ></FormControlLabel>
                  );
                }}
                name="privacy"
                rules={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                }}
              />
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.privacy && errors.privacy.message}
              </p>
            </div>

            <MainButton
              title={"Send"}
              width={"w-48 max-h-14 lg:max-w-14"}
              Icon={<SendIcon></SendIcon>}
              action={handleSubmit(submitMail)}
            ></MainButton>
          </div>
          <p className="text-xs text-green" style={{ minHeight: "1rem" }}>
            {response}
          </p>
        </div>
      </div>
    </form>
  );
};

export default ContactSection;
