import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import logo from "../../staticfiles/Logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, clearError } from "../../redux/modules/auth/actions";

import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import MainButton from "../../Components/ui/buttons/MainButton";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user, error } = useSelector((state) => state.auth);
  const [values, setValues] = React.useState({
    email: "",
    mailSent: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "Admin") {
        navigate("/models");
      } else if (user.role === "userA") {
        navigate("/my-models");
      }
    }
    dispatch(clearError());
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const forgotCallback = () => {
    setValues({
      ...values,
      email: "",
      mailSent: "An email to reset your password was sent",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(forgotPassword(values.email, forgotCallback));
  };
  useEffect(() => {
    if (error && error.length > 0) {
      setShowErrors(true);
    }
  }, [error]);
  const errorDisplay = () => {
    if (error.length > 0) {
      return error.map((error, i) => {
        return (
          <p
            key={i}
            className="text-xs text-red-500 "
            style={{ minHeight: "1rem" }}
          >
            {error.message}
          </p>
        );
      });
    }
  };
  return (
    <Page>
      <Container className="flex items-center">
        <img
          src={logo}
          style={{ margin: "0 auto" }}
          className="logo h-56 w-72"
        />
        <div className="z-10 w-full bg-white mt-5 p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <div className="py-7">
            <h2 className="font-bold text-center text-black py-2.5">
              Recover your password
            </h2>
            <p className="font-medium text-center text-fade leading-7 tracking-wide">
              Enter your email to recover your password
            </p>
          </div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="form flex flex-col justify-between items-center w-full"
          >
            <div className="w-full sm:w-5/6 flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
              <MailOutlineIcon />
              <input
                value={values.email}
                placeholder="Email"
                onChange={handleChange("email")}
                className="flex flex-1 appearance-none  w-1/2 py-3 ml-5 leading-tight focus:outline-none"
              />
            </div>
            <div className="w-full flex justify-evenly my-2">
              <Link className="text-primary font-medium text-right" to="/login">
                Login
              </Link>
            </div>
            {showErrors && error.length > 0 && errorDisplay()}
            {values.mailSent !== "" && (
              <div className="rounded-xl bg-green-100 px-2 py-1 text-green-700 flex justify-center">
                {values.mailSent}
              </div>
            )}
            <div className="flex justify-center w-full my-2">
              <MainButton
                title="Recover password"
                type="submit"
                width="w-full sm:w-5/6"
              />
            </div>
          </form>
        </div>
      </Container>
    </Page>
  );
};

export default ForgotPassword;
